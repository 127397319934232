import Aos from "aos";
import React, { useEffect } from "react";

export default function TickerTape() {
  const technicalSignals = [
    {
      id: 1,
      title: "Market Overview",
      embedHeight: 390,
      animation: "zoom-in-up",
      embedUrl:
        "https://darqube.com/external-embedding/ticker-tape?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3aWRnZXQiOnsiYXNzZXRfY2xhc3NlcyI6WyJDUllQVE8iXX0sIndfdHlwZSI6IlRpY2tlclRhcGUiLCJmZV9jZmciOnsiY21vZGUiOjAsImZjbHIiOiJyZ2JhKDI1NSwgMjU1LCAyNTUsIDEpIiwiYmciOiJyZ2JhKDIxLCAyNSwgMzAsIDEpIiwiaCI6MzczLCJ3IjoxMTAwLCJhc3oiOnRydWUsImZ0IjoiZGVmYXVsdCIsImh0Ijoibm9uZSIsInRyaCI6W10sImNoYyI6InJnYmEoMjM3LCA1MCwgOTgsIDEpIiwiY24iOiIiLCJsbmciOiJlbiIsImN0cmx0IjoxLCJoZGljbiI6ZmFsc2UsInd0bVYiOnsidHlwZSI6IkRhcnF1YmUiLCJlbmFibGVkIjp0cnVlfSwiY3RybHYiOjEsInNtYiI6W3sibiI6IkJUQy9VUyBEb2xsYXIiLCJ0IjoiQlRDVVNELkhJVEJUQyJ9LHsibiI6IkFEQS9VUyBEb2xsYXIiLCJ0IjoiQURBVVNELktSQUtFTiJ9LHsibiI6IkV0aGVyZXVtIiwidCI6IkVUSFVTRC5BR1IifSx7Im4iOiJYUlAiLCJ0IjoiWFJQVVNELkFHUiJ9LHsibiI6IlNvbGFuYSIsInQiOiJTT0xVU0QuQUdSIn0seyJuIjoiUG9seWdvbiIsInQiOiJNQVRJQ1VTRC5BR1IifSx7Im4iOiJQb2xrYWRvdCBbSU9VXSIsInQiOiJET1RVU0QuQUdSIn0seyJuIjoiU2hpYmEgSW51IiwidCI6IlNISUJVU0QuQUdSIn0seyJuIjoiRG9nZWNvaW4iLCJ0IjoiRE9HRVVTRC5BR1IifV0sIm9wbiI6dHJ1ZX0sImV4cCI6MTY3NTM0MzExNiwic3ViIjoiYWNjZXNzIn0.jy58bBKlsGiJBEmhD0dPEjfGC6zQlqU5P8a1V3EAJGM",
    },
    {
      id: 2,
      title: "Crypto list",
      animation: "zoom-in-down",
      embedHeight: 390,
      embedUrl:
        "https://www.tradingview-widget.com/embed-widget/crypto-mkt-screener/?locale=in#%7B%22width%22%3A1000%2C%22height%22%3A490%2C%22defaultColumn%22%3A%22overview%22%2C%22screener_type%22%3A%22crypto_mkt%22%2C%22displayCurrency%22%3A%22USD%22%2C%22colorTheme%22%3A%22light%22%2C%22market%22%3A%22crypto%22%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22cryptomktscreener%22%7D",
    },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div className="py-8">
      <div className="grid gap-4 grid-cols-1">
        {technicalSignals.map((tickerTape) => (
          <div
            key={tickerTape.id}
            className="border p-1 rounded-md bg-transparent shadow-lg"
            data-aos={tickerTape.animation}
          >
            <h3 className="text-xl rounded-md pl-2 py-5 bg-sky-600 text-center text-white  font-semibold mb-2">
              {tickerTape.title}
            </h3>

            <iframe
              title={`tickerTape ${tickerTape.id}`}
              src={tickerTape.embedUrl}
              height={tickerTape.embedHeight}
              className="w-full rounded-md"
              allowtransparency="true"
              lang="en"
            ></iframe>
          </div>
        ))}
        <div>
          <coingecko-coin-heatmap-widget
            height="400"
            locale="en"
          ></coingecko-coin-heatmap-widget>
        </div>
      </div>
    </div>
  );
}
