import Aos from "aos";
import React, { useEffect } from "react";

export default function ForexTrading() {
  const forexTradingTools = [
    {
      id: 1,
      title: "Trading tool 1",
      animation: "fade-right",
      embedUrl:
        "https://www.tradingview-widget.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22description%22%3A%22EUR%2FUSD%22%2C%22proName%22%3A%22FX%3AEURUSD%22%7D%2C%7B%22description%22%3A%22GBP%2FUSD%22%2C%22proName%22%3A%22FX%3AGBPUSD%22%7D%2C%7B%22description%22%3A%22AUD%2FUSD%22%2C%22proName%22%3A%22FX%3AAUDUSD%22%7D%2C%7B%22description%22%3A%22NZD%2FUSD%22%2C%22proName%22%3A%22FX%3ANZDUSD%22%7D%2C%7B%22description%22%3A%22USD%2FJPY%22%2C%22proName%22%3A%22FX%3AUSDJPY%22%7D%2C%7B%22description%22%3A%22USD%2FCAD%22%2C%22proName%22%3A%22FX%3AUSDCAD%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22compact%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A106%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22forexsalaryconnect.com%2F%3Fpage_id%3D1249%22%7D",
    },
    {
      id: 2,
      title: "Trading tool 2",
      animation: "fade-left",
      embedUrl:
        "https://www.tradingview-widget.com/embed-widget/tickers/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%2FUSD%22%7D%2C%7B%22description%22%3A%22GBP%2FUSD%22%2C%22proName%22%3A%22OANDA%3AGBPUSD%22%7D%2C%7B%22description%22%3A%22AUD%2FUSD%22%2C%22proName%22%3A%22OANDA%3AAUDUSD%22%7D%2C%7B%22description%22%3A%22NZD%2FUSD%22%2C%22proName%22%3A%22FX%3ANZDUSD%22%7D%2C%7B%22description%22%3A%22USD%2FCAD%22%2C%22proName%22%3A%22FX%3AUSDCAD%22%7D%2C%7B%22description%22%3A%22USD%2FJPY%22%2C%22proName%22%3A%22FX%3AUSDJPY%22%7D%2C%7B%22description%22%3A%22USD%2FCHF%22%2C%22proName%22%3A%22FX%3AUSDCHF%22%7D%5D%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22showSymbolLogo%22%3Atrue%2C%22width%22%3A%22100%25%22%2C%22height%22%3A104%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22tickers%22%2C%22page-uri%22%3A%22forexsalaryconnect.com%2F%3Fpage_id%3D1249%22%7D",
    },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div className="py-8">
      <h3 className="text-xl rounded-md pl-2 py-5 bg-sky-600 text-center text-white  font-semibold mb-2">
        Forex Trading
      </h3>
      <div className="grid gap-4 grid-cols-1">
        {forexTradingTools.map((forexTools) => (
          <div
            key={forexTools.id}
            className="border p-1 rounded-md bg-transparent shadow-lg"
            data-aos={forexTools.animation}
          >
            {/* <h3 className="text-xl font-semibold mb-2">{forexTools.title}</h3> */}
            <iframe
              title={`forexTools ${forexTools.id}`}
              src={forexTools.embedUrl}
              //   height={152}
              className="w-full rounded-md h-24"
              allowtransparency="true"
              lang="en"
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}
