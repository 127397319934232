import React, { useEffect } from "react";
import ActBanner from "./components/ActBanner";
import Aos from "aos";

export default function AckAndAgmt() {
    useEffect(() => {
        Aos.init({
          duration: 1000,
          delay: 5000,
        });
      }, []);
  return (
    <div>
      <ActBanner />
      <section className="container">
        <blockquote data-aos="fade-down-right" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "By using this website, the user acknowledges and agrees that the
            author and any other entities associated with Forex Salary Connect
            shall not be held liable for any direct, indirect, consequential
            loss, or any damages whatsoever arising from this usage, or the use
            of any information, signals, messages, education, and any other
            information contained or disseminated regarding its use and
            understanding. The user expressly understands and agrees to use this
            site and the services offered by Forex Salary Connect at their own
            risk."
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-left" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "Forex Salary Connect and its associated entities make no guarantee
            of performance results nor offer any anticipated return on
            investment at any time. Past performance is not indicative of future
            results. None of the information provided by Forex Salary Connect
            constitutes a solicitation to engage in any trading/investment
            activity."
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-right" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "By utilizing the Forex Salary Connect website and other services
            provided, you explicitly agree not to hold Forex Salary Connect, or
            any of its affiliates, liable for decisions based on information
            contained in blog posts, reader responses to blog posts, or
            information anywhere else on their website or in promotional
            material."
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-left" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "Furthermore, the user acknowledges that the financial markets
            involve substantial risks, including the potential loss of invested
            capital, and that investment decisions should be made based on
            individual discretion and consultation with a qualified financial
            advisor. The user agrees to conduct their own due diligence before
            engaging in any financial transactions or investments based on
            information obtained from Forex Salary Connect."
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-right" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "The user also agrees to indemnify and hold harmless Forex Salary
            Connect, its officers, employees, and affiliates from any claims,
            losses, damages, liabilities, costs, and expenses, including legal
            fees, arising out of or related to the user's violation of these
            terms or their use of the website and services provided."
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-left" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "By continuing to use the Forex Salary Connect website and services,
            the user acknowledges that they have read, understood, and agreed to
            abide by the terms and conditions outlined in this Acknowledgement
            and Agreement."
          </p>
        </blockquote>
      </section>
    </div>
  );
}
