import React from "react";

export default function Disclaimer() {
  return (
    <div>
      <section className=" dark:text-gray-50">
        <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
          <h2 className="text-2xl font-semibold sm:text-4xl">
            Disclaimer: Important Information About Forex Broker Promotion
          </h2>
          <p className="mt-4 mb-8 dark:text-gray-100">
            The promotion shown above is from various forex broker platforms and
            is subject to their respective terms and conditions. Please read
            this disclaimer carefully before participating in the promotion.
          </p>
          <div className="space-y-4">
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:ri">
                Eligibility:
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-100">
                This promotion is available on various broker platforms.
                Participants must meet the eligibility requirements outlined in
                the promotion terms.
              </p>
            </details>
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:ri">
                Promotion Period:
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-100">
                The promotion is valid according to the broker promotion
                periods. Any registrations or activities outside this period
                will not be considered.
              </p>
            </details>
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:ri">
                Bonus or Incentives:
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-100">
                Participants will receive the bonus or incentives as outlined in
                the promotion terms upon meeting the specified conditions of
                forex broker platforms.
              </p>
            </details>
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:ri">
                Risk Warning:
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-100">
                Forex trading involves a high level of risk and may not be
                suitable for all investors. Before participating in any of the
                above promotions, ensure you fully understand the risks involved
                and seek independent financial advice if necessary.
              </p>
            </details>
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:ri">
                Terms and Conditions:
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-100">
                By participating in the above promotion, you must agree to abide
                by the terms and conditions set forth by the promotion's broker.
                Failure to comply with their terms may result in
                disqualification from the promotion.
              </p>
            </details>
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:ri">
                Withdrawal Conditions:
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-100">
                Participants must meet certain criteria specified by various
                brokers on their websites before being eligible to withdraw the
                bonus or incentives. Please refer to the promotion terms from
                the broker's websites.
              </p>
            </details>
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:ri">
                Modification or Termination:
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-100">
                The respective broker providing the service reserves the right
                to modify or terminate the promotion at any time without prior
                notice. Any changes will be communicated by their concerned team
                and channel. Legal Compliance: This promotion is subject to all
                applicable laws and regulations. Participants are responsible
                for ensuring compliance with their local laws regarding forex
                trading and promotions.
              </p>
            </details>
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:ri">
                Legal Compliance:
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-100">
                This promotion is subject to all applicable laws and
                regulations. Participants are responsible for ensuring
                compliance with their local laws regarding forex trading and
                promotions.
              </p>
            </details>
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:ri">
                Note:
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-100">
                Forex Salary is not offering this broker promotion. It is
                offered by a forex broker, and we are simply presenting and
                explaining their promotion.
              </p>
            </details>
          </div>
        </div>
      </section>
    </div>
  );
}
