import React, { useEffect, useState } from "react";

const GoogleFormRedirect = () => {
  const [redirecting, setRedirecting] = useState(true);

  useEffect(() => {
    const delay = 4000; // 5 seconds in milliseconds

    const redirectTimer = setTimeout(() => {
      setRedirecting(false);
      // Redirect to Google Form link after the delay
      window.location.href =
        "https://docs.google.com/forms/d/e/1FAIpQLSdvLuniiExpGuEFCV7P1x4K6vvjj6hEPSrxnfWRgzMWylIIiQ/viewform?usp=sf_link";
    }, delay);

    // Clear the timer if the component unmounts
    return () => clearTimeout(redirectTimer);
  }, []);

  // You can render a loading indicator or a message while redirecting
  return (
    <div className="flex justify-center h-svh">
      <div className="m-auto">
        {redirecting && (
          <div className="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
        <p className="text-3xl">Redirecting to Google Form...</p>
      </div>
    </div>
  );
};

export default GoogleFormRedirect;
