import Aos from "aos";
import React, { useEffect } from "react";

export default function AboutBody() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div>
      <section className="text-white body-font">
        <div className="mx-auto flex flex-col">
          <div className="mx-auto">
            <div className="flex flex-col sm:flex-row mt-10">
              <div className="sm:w-1/3 mx-auto text-center sm:pr-8 sm:py-8" data-aos="fade-right">
                <div className="inline-flex items-center justify-center text-gray-400">
                  <img
                    className="w-1/2 rounded-full"
                    src="/images/profilePic.jpg"
                    alt="kulwant rana profile"
                  ></img>
                </div>
                <div className="flex flex-col items-center text-center justify-center">
                  <h2 className="font-medium title-font mt-4 text-white text-lg">
                    Kulwant Rana
                  </h2>
                  <div className="w-12 h-1 bg-sky-500 rounded mt-2 mb-4" />
                  <p className="text-base">
                    Full time Trader & Coach,
                    Founder “FOREXSALARY” & “FOREXSALARYCONNECT”
                  </p>
                  
                <div className="grid grid-cols-2">
                  <div className="mb-4 items-center justify-center">
                    <img
                      src="/images/CMAlogo,_to_be_used_by_Indian_CMAs.jpg"
                      className="h-auto w-1/2 rounded-lg mx-auto"
                      alt=""
                    />
                  </div>
                  <div className="mb-4">
                    <img
                      src="/images/CIMA_logo_full.jpg"
                      className="h-auto w-1/2 pt-2 rounded-lg"
                      alt=""
                    />
                  </div>
                </div>
                </div>
              </div>
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left" data-aos="fade-left">
                <h1 className="my-2 text-2xl font-semibold text-white md:text-3xl ">
                  Our Founder's Journey
                </h1>
                <p className="leading-relaxed text-lg mb-4">
                  Forex Salary was born from a deeply personal journey, one
                  marked by determination, perseverance, and a drive to excel in
                  the dynamic world of Forex trading. Our founder, Kulwant Rana,
                  faced formidable challenges on the path to becoming a
                  professional Forex Trader. At the outset, the lack of clear
                  guidance and mentorship posed significant obstacles.
                  Undeterred, Kulwant Rana dedicated four years to comprehending
                  intricate market concepts, ultimately achieving success in the
                  realm of Forex trading.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
