import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import moment from "moment";
import YoutubeSkelton from "../Alerts/YoutubeSkelton";
import Snackbar from "../Alerts/Snackbar";
import Aos from "aos";
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/20/solid"; // Import these icons from the Heroicons library

export default function VideoList() {
  const [videoDetails, setVideoDetails] = useState([]);
  const [originalVideoDetails, setOriginalVideoDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState("All"); // State for selected video type

  const videosPerPage = 8;
  const startIndex = (currentPage - 1) * videosPerPage;
  const endIndex = startIndex + videosPerPage;

  const getData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const res = await axiosClient.get("/api/v1/youtube/youtubelist");
      const sortedVideos = res.data.sort(
        (a, b) => moment(b.time) - moment(a.time)
      );
      setVideoDetails(sortedVideos);
      setOriginalVideoDetails(sortedVideos); // Store original video data
      setTotalPages(Math.ceil(sortedVideos.length / videosPerPage));
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);

  useEffect(() => {
    // Filter videos based on selected type
    if (selectedType === "All") {
      setVideoDetails(originalVideoDetails); // Reset to original data
      setTotalPages(Math.ceil(originalVideoDetails.length / videosPerPage));
      setCurrentPage(1); // Reset to first page when type changes
    } else {
      const filteredVideos = originalVideoDetails.filter(
        (video) => video.type === selectedType
      );
      setVideoDetails(filteredVideos);
      setTotalPages(Math.ceil(filteredVideos.length / videosPerPage));
      setCurrentPage(1); // Reset to first page when type changes
    }
  }, [selectedType, originalVideoDetails]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  return (
    <div>
      {isLoading && <YoutubeSkelton />}
      {!!error ? (
        <Snackbar message={error} type="failed" />
      ) : (
        <section className="py-6 sm:py-12 ">
          <div className="mx-auto space-y-8">
            <div className="flex justify-end">
              <select
                value={selectedType}
                onChange={handleTypeChange}
                className="w-1/2 lg:w-1/4 relative block px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="All">All Types</option>
                <option value="Forex">Forex</option>
                <option value="Crypto">Crypto</option>
                <option value="Trading">Trading</option>
                <option value="Broker">Broker</option>
                <option value="Funding Firms">Funding Firms</option>
                <option value="Live Webinar">Live Webinar</option>
                <option value="Strategy">Strategy</option>
                <option value="Forex Tools">Forex Tools</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-4">
              {videoDetails.slice(startIndex, endIndex).map((video, index) => (
                <article
                  key={index}
                  className="flex flex-col rounded-md hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)] "
                  data-aos="flip-down"
                >
                  <iframe
                    src={video.url}
                    title={video.title}
                    allowFullScreen
                    className="w-full p-1 rounded-lg aspect-video"
                  ></iframe>
                  <div className="flex flex-col flex-1 p-2 ">
                    <p className="text-left text-white ">{video.title}</p>
                    <div className="flex flex-wrap justify-between pt-2 space-x-1 text-sm ">
                      <span>{moment(video.time).format("ll")}</span>
                      <span>{video.type}</span>
                    </div>
                  </div>
                </article>
              ))}
            </div>
            <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-50 bg-gray-700 p-2">
                  Showing <span className="font-medium">{startIndex + 1}</span>{" "}
                  to <span className="font-medium">{endIndex}</span> of{" "}
                  <span className="font-medium">{videoDetails.length}</span>{" "}
                  results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-white  ring-1 ring-inset ring-gray-300 hover:bg-gray-500 focus:z-20 focus:outline-offset-0 ${
                      currentPage === 1 ? "pointer-events-none opacity-50 " : ""
                    }`}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <span className="sr-only text-white ">Previous</span>
                    <ChevronDoubleLeftIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                  {[...Array(totalPages).keys()].map((page) => (
                    <button
                      key={page + 1}
                      onClick={() => handlePageChange(page + 1)}
                      className={`relative ${
                        currentPage === page + 1
                          ? "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          : "text-gray-50 ring-1 ring-inset ring-gray-300 hover:bg-gray-500 focus:outline-offset-0"
                      } inline-flex items-center px-4 py-2 text-sm font-semibold`}
                    >
                      {page + 1}
                    </button>
                  ))}
                  <button
                    className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-500 focus:z-20 focus:outline-offset-0 ${
                      currentPage === totalPages
                        ? "pointer-events-none opacity-50"
                        : ""
                    }`}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <span className="sr-only ">Next</span>
                    <ChevronDoubleRightIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
