import React from 'react'
import { Helmet } from "react-helmet";

export default function ForexBookHelmet() {
  return (
    <div>
      <Helmet>
        <title>Forex Books</title>
        <meta
          name="description"
          content="Explore a wide array of literature, covering topics from trading psychology to inspiring biographies. Leveraging various channels to expand your understanding is key to continuous growth, regardless of your specific trading endeavors."
        />
      </Helmet>
    </div>
  )
}
