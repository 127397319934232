import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import moment from "moment";
import Aos from "aos";
import YoutubeSkelton from "../Alerts/YoutubeSkelton";
import Snackbar from "../Alerts/Snackbar";

export default function DownloadResources() {
  const [resourceDetails, setResourceDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const res = await axiosClient.get("/api/v1/resource");
      const sortedResources = res.data.sort(
        (a, b) => moment(b.time) - moment(a.time)
      );
      setResourceDetails(sortedResources);
      console.log(sortedResources);
    } catch (err) {
      // console.log(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);

  return (
    <div>
      {isLoading && <YoutubeSkelton />}
      {!!error ? (
        <Snackbar message={error} type="failed" />
      ) : (
        <div className="mx-auto">
          <h1 className="text-2xl font-bold mb-4">Download Resources</h1>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-transparent">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider"
                >
                  Document/Tool Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider"
                >
                  Upload Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider"
                >
                  Download Links
                </th>
              </tr>
            </thead>
            <tbody className="bg-transparent divide-y divide-gray-800">
              {resourceDetails.map((document, index) => (
                <tr key={index} data-aos="zoom-out-down">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-1">
                        <div className="text-md font-medium text-gray-50 ">
                          {index + 1}. {document.resourceName}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-0">
                        <div className="text-md font-medium text-gray-50 ">
                          {moment(document.uploadDate).format("DD-MM-YYYY")}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium">
                    <a
                      href={document.url}
                      className="text-sky-500 hover:text-sky-900"
                      target="_0"
                    >
                      <div className="flex items-center">
                        <p>Download &nbsp; </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                          />
                        </svg>
                      </div>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
