import React from "react";
import { Helmet } from "react-helmet";

export default function YoutubeHelmet() {
  return (
    <div>
      <Helmet>
        <title>Youtube</title>
        <meta
          name="description"
          content="Explore our curated collection of videos focusing on fundamental concepts essential for any aspiring Forex trader. Learn about currency pairs, market dynamics, and more."
        />
      </Helmet>
    </div>
  );
}
