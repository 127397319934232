import React from 'react'
import { Helmet } from "react-helmet";


export default function ForexCalenderHelmet() {
  return (
    <div><Helmet>
    <title>Forex Calendar</title>
    <meta
      name="description"
      content="Keep track of important economic events and announcements with our comprehensive Forex calendar. Stay ahead of market movements and make informed trading decisions."
    />
  </Helmet></div>
  )
}
