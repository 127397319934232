import React from "react";
import { Helmet } from "react-helmet";

export default function CryptoTradingHelmet() {
  return (
    <div>
      <Helmet>
        <title>Crypto Trading</title>
        <meta
          name="description"
          content="Whether you're a novice trader or an experienced investor, our crypto trading center equips you with the knowledge, tools, and insights needed to thrive in this dynamic landscape."
        />
        <script src="https://widgets.coingecko.com/coingecko-coin-heatmap-widget.js"></script>
      </Helmet>
    </div>
  );
}
