import {
    BookOpenIcon,
    WrenchIcon,
    AcademicCapIcon,
    CalendarDaysIcon
  } from "@heroicons/react/24/outline";
export const forexTrading=[
    {
        name: "Forex Tools",
        description: "Forex trading tools are available here.",
        href: "/forex-tools",
        icon: WrenchIcon,
      },
      {
        name: "Forex Calender",
        description: "Forex news and other tools for forex traders.",
        href: "/forex-calendar",
        icon: CalendarDaysIcon,
      },
      {
        name: "Forex Books",
        description: "Top recommended books from forex salary.",
        href: "/forex-book",
        icon: BookOpenIcon,
      },
      {
        name: "Forex Courses",
        description: "Join the exclusive Price action course.",
        href: "/forex-courses",
        icon: AcademicCapIcon,
      },
]