import Aos from "aos";
import React, { useEffect } from "react";

export default function AboutBanner() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div>
      <section className="relative py-6 sm:py-12 ">
        <div className="absolute top-0 left-0 w-full h-full opacity-40"></div>
        <div className="relative z-10 gap-5 items-center lg:flex">
          <div
            className="flex-1 max-w-lg py-5 sm:mx-auto sm:text-center lg:max-w-max lg:text-left"
            data-aos="fade-right"
          >
            <div className="mt-5 px-4 py-2 text-sky-600 font-medium bg-sky-50 rounded-full inline-flex items-center">
              About Us
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 ml-1 duration-150"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
            </div>

            <h3 className="text-3xl text-white font-semibold md:text-4xl">
              Empowering Indian Traders for
              <br />
              <span className="text-sky-500 text-2xl md:text-3xl">Success</span>
            </h3>
            <p className="text-white leading-relaxed mt-3">
              Welcome to Forex Salary, your trusted resource for mastering forex
              trading in India. With tailored guidance and proven strategies, I
              empower traders to navigate the complexities of the forex market
              with confidence. From personalized coaching to ongoing support,
              I'm dedicated to helping you achieve your trading goals. Join me
              on your journey to success in the dynamic world of forex trading.
            </p>
          </div>
          <div
            className="flex-1 mt-5 mx-auto sm:w-9/12 lg:mt-0 lg:w-auto hover:shadow-[0px_10px_20px_rgba(225,_212,_247,_1)]"
            data-aos="fade-left"
          >
            <img
              src="/images/aboutme_image.webp"
              alt="banner about us page"
              className="w-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
