import React from "react";
import ForexTrading from "./ForexTrading";
import TechnicalSignals from "./TechnicalSignals";
import TickerTape from "./TickerTape";
import ForexNews from "./ForexNews";
import ForexCalculator from "./ForexCalculator";
import ForexToolHelmet from "../Helmets/ForexToolHelmet";
import Partner from "../Homepage/Partner";

export default function Index() {
  return (
    <div className="container">
      <ForexToolHelmet/>
      <ForexTrading />
      <TechnicalSignals />
      <TickerTape />
      <ForexNews />
      <ForexCalculator />
      {/* <Partner /> */}
    </div>
  );
}
