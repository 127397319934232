import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import moment from "moment";
import Aos from "aos";

export default function YoutubeBanner() {
  const [videoDetails, setVideoDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const res = await axiosClient.get("/api/v1/youtube/youtubelist");
      const sortedVideos = res.data.sort(
        (a, b) => moment(b.time) - moment(a.time)
      );
      setVideoDetails(sortedVideos);
      // console.log(sortedVideos);
    } catch (err) {
      // console.log(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);

  const latestVideoUrl = videoDetails.length > 0 ? videoDetails[0].url : "";

  return (
    <div>
      <section className="cta-sec relative py-6 sm:py-12 ">
        <div className="absolute top-0 left-0 w-full h-full  opacity-40"></div>
        <div className="relative z-10 gap-5 items-center lg:flex">
          <div
            className="flex-1 max-w-lg py-5 sm:mx-auto sm:text-center lg:max-w-max lg:text-left"
            data-aos="fade-right"
          >
            <div className="mt-5 px-4 py-2 text-sky-600 font-medium bg-sky-50 rounded-full inline-flex items-center">
              Youtube
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 ml-1 duration-150"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
            </div>
            <h3 className="text-3xl text-white font-semibold md:text-4xl">
              Advanced Trading Strategies
              <br />
              <span className="text-sky-500 text-2xl md:text-3xl">
                Maximizing Your Profits
              </span>
            </h3>
            <p className="text-white leading-relaxed mt-3">
              Explore our curated collection of videos focusing on fundamental
              concepts essential for any aspiring Forex trader. Learn about
              currency pairs, market dynamics, and more.
            </p>
          </div>
          <div
            className=" flex-1 mt-5 mx-auto sm:w-9/12 lg:mt-0 lg:w-full hover:shadow-[0px_10px_20px_rgba(225,_212,_247,_1)]"
            data-aos="fade-left"
          >
            {isLoading ? (
              <div>Loading...</div>
            ) : !!error ? (
              <div>Error: {error}</div>
            ) : (
              <iframe
                src={`${latestVideoUrl}?autoplay=1&cc_load_policy=1&controls=0&disablekb=1&modestbranding=1&start=0&iv_load_policy=3`}
                title="Latest Video"
                allowFullScreen
                className="w-full rounded-lg aspect-video	"
                
              ></iframe>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
