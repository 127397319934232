import React from "react";
import { Link } from "react-router-dom";

export default function SocialPresence() {
  return (
    <div >
      <div className="py-16 mx-auto container lg:py-20">
        <div
          className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8"
          data-aos="zoom-in-down"
        >
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">
              Your ultimate stop for
              <br className="hidden md:block" />
              Forex Education
            </span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-sky-accent-400 scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-white lg:max-w-md">
            Welcome to the ultimate destination for mastering the art of trading
            in the foreign exchange market and beyond. Our comprehensive
            educational resources and interactive sessions will equip you with
            the necessary knowledge and skills to succeed in Forex, Crypto,
            Stocks, and CFD trading.
          </p>
        </div>
        <div
          className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-4 sm:row-gap-6 sm:grid-cols-2"
          data-aos="fade-down"
          data-aos-delay="600"
        > 
          <Link to="/youtube" aria-label="View Item" className="hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]">
            <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src="/images/youtube.webp"
                alt="youtube video"
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-50 opacity-0 hover:opacity-100">
                <p className="mb-4 text-2xl font-bold text-sky-300">
                  01. YouTube - Forex Salary Channel:
                </p>
                <p className="text-sm tracking-wide text-gray-100">
                  Join our YouTube channel, Forex Salary, where you'll find
                  premium, gold-standard educational content. Dive into in-depth
                  tutorials and gain valuable insights that will help you
                  develop the essential skills needed to trade effectively and
                  witness real results in your trading journey.
                </p>
              </div>
            </div>
          </Link>
          <Link to="/podcast" aria-label="View Item" className="hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]">
            <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src="/images/forex_podcast.webp"
                alt="podcast section"
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-50 opacity-0 hover:opacity-100">
                <p className="mb-4 text-2xl font-bold text-sky-300">
                  02. Podcast - Trading Insights:
                </p>
                <p className="text-sm tracking-wide text-gray-100">
                  Tune in to our enriching podcast, "Trading Insights," where we
                  share our experiences and connect with our audience. Stay
                  updated with our regular episodes, each packed with valuable
                  tips and knowledge that will enhance your understanding of the
                  financial markets.
                </p>
              </div>
            </div>
          </Link>
          <Link to="/youtube" aria-label="View Item" className="hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]">
            <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src="/images/forex_webinar.webp"
                alt="webinar video"
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-50 opacity-0 hover:opacity-100">
                <p className="mb-4 text-2xl font-bold text-sky-300">
                  03. Live Webinars - Learn in Real Time:
                </p>
                <p className="text-sm tracking-wide text-gray-100">
                  Participate in our live webinar sessions on YouTube and
                  private webinars via Zoom, held in collaboration with esteemed
                  partners like Exness, Tickmill, XM, and more. These
                  interactive sessions offer a platform to learn from experts
                  and get your questions answered in real time.
                </p>
              </div>
            </div>
          </Link>
          <Link to="/forex-tools" aria-label="View Item" className="hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]">
            <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src="/images/forex_tools.webp"
                alt="tradingTools video"
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-50 opacity-0 hover:opacity-100">
              <p className="mb-4 text-2xl font-bold text-sky-300">
                  04. Forex Tools and Resources - Your Comprehensive Trading
                  Hub:
                </p>
                <p className="text-sm tracking-wide text-gray-100">
                  Explore our website's section for Forex tools and resources,
                  providing a one-stop access to essential materials, guides,
                  articles, Forex calendars, and more. Enhance your trading
                  strategies and stay updated on market trends effortlessly in
                  this user-friendly space.
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
