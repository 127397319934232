import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import YoutubeSkelton from "../Alerts/YoutubeSkelton";
import Snackbar from "../Alerts/Snackbar";
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/20/solid";
import moment from "moment";
import Aos from "aos";

export default function PodShow() {
  const [podcastDetails, setPodcastDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const podcastsPerPage = 6;

  const getData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const res = await axiosClient.get("/api/v1/podcast/getPodcastlist");
      const sortedPodcasts = res.data.sort(
        (a, b) => moment(b.time) - moment(a.time)
      );
      setPodcastDetails(sortedPodcasts);
      // console.log(sortedPodcasts);
      setTotalPages(Math.ceil(sortedPodcasts.length / podcastsPerPage));
    } catch (err) {
      // console.log(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * podcastsPerPage;
  const endIndex = startIndex + podcastsPerPage;

  return (
    <div>
      {isLoading && <YoutubeSkelton />}
      {!!error ? (
        <Snackbar message={error} type="failed" />
      ) : (
        <section className="py-6 sm:py-12 ">
          <div className="mx-auto space-y-8">
            <div className="grid gap-4 pb-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              {podcastDetails.slice(startIndex, endIndex).map((podcast) => (
                <div
                  key={podcast._id}
                  className="border p-1 rounded-md bg-transparent shadow-lg hover:shadow-[0px_10px_20px_rgba(225,_212,_247,_1)]"
                  data-aos="flip-up"
                >
                  <iframe
                    key={podcast._id}
                    title={`Podcast ${podcast._id}`}
                    src={podcast.url}
                    height={152}
                    className="w-full rounded-md"
                    allowtransparency="true"
                    allow="encrypted-media"
                  ></iframe>
                </div>
              ))}
            </div>
            {totalPages > 1 && (
              <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-50 bg-gray-700 p-2">
                    Showing{" "}
                    <span className="font-medium">{startIndex + 1}</span> to{" "}
                    <span className="font-medium">{endIndex}</span> of{" "}
                    <span className="font-medium">{podcastDetails.length}</span>{" "}
                    results
                  </p>
                </div>
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <button
                      className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-50 ring-1 ring-inset ring-gray-300 hover:bg-gray-500 focus:z-20 focus:outline-offset-0 ${
                        currentPage === 1
                          ? "pointer-events-none opacity-50"
                          : ""
                      }`}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronDoubleLeftIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                    {[...Array(totalPages).keys()].map((page) => (
                      <button
                        key={page + 1}
                        onClick={() => handlePageChange(page + 1)}
                        className={`relative ${
                          currentPage === page + 1
                            ? "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            : "text-gray-50 ring-1 ring-inset ring-gray-300 hover:bg-gray-500 focus:outline-offset-0"
                        } inline-flex items-center px-4 py-2 text-sm font-semibold`}
                      >
                        {page + 1}
                      </button>
                    ))}
                    <button
                      className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-50 ring-1 ring-inset ring-gray-300 hover:bg-gray-500 focus:z-20 focus:outline-offset-0 ${
                        currentPage === totalPages
                          ? "pointer-events-none opacity-50"
                          : ""
                      }`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronDoubleRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
}
