import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
 
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { forexTrading } from "./components/forexTrading";
import { startHere } from "./components/startHere";
import { tradingTools, brokers } from "./components/MixedHeader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigate = useNavigate();

  const scrollTarget = (target) =>
    scroller.scrollTo(target, { smooth: true, duration: 700 });

  const scrollToPage = (target) => {
    if (window.location.pathname !== "/") {
      navigate("/");
      setTimeout(() => scrollTarget(target), 100);
    } else {
      scrollTarget(target);
    }
  };

  return (
    <header className="bg-black">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 "
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Forex Salary</span>
            <img className="h-14 w-auto" src="logo.png" alt="" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <Popover.Button className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 flex items-center gap-x-1 font-heading  text-white hover:text-gray-200">
              Start Here
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400 ui-open:rotate-180 ui-open:transform"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-30 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-gray-900 shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {startHere.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-800"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-sky-500 group-hover:text-sky-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <Link
                          to={item.href}
                          className="block font-heading  text-white hover:text-gray-200"
                          onClick={() =>
                            document.getElementById("popover-button").click()
                          }
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1  text-white hover:text-gray-200">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 flex items-center gap-x-1 font-heading  text-white hover:text-gray-200">
              Forex Trading
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400 ui-open:rotate-180 ui-open:transform"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-30 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-gray-900 shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {forexTrading.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-800"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-sky-500 group-hover:text-sky-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <Link
                          to={item.href}
                          className="block font-heading  text-white hover:text-gray-200"
                          onClick={() =>
                            document.getElementById("popover-button").click()
                          }
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1  text-white hover:text-gray-200">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <Popover className="relative">
            <Popover.Button className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 flex items-center gap-x-1 font-heading  text-white hover:text-gray-200">
              Brokers
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400 ui-open:rotate-180 ui-open:transform"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-30 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-gray-900 shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {brokers.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-800"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-sky-500 group-hover:text-sky-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <Link
                          to={item.href}
                          className="block font-heading  text-white hover:text-gray-200"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1  text-white hover:text-gray-200">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 flex items-center gap-x-1 font-heading  text-white hover:text-gray-200">
              Trading Tools
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400 ui-open:rotate-180 ui-open:transform"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-30 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-gray-900 shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {tradingTools.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-800"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-sky-500 group-hover:text-sky-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <Link
                          to={item.href}
                          className="block font-heading  text-white hover:text-gray-200"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1  text-white hover:text-gray-200">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Link
            to="/"
            onClick={() => scrollToPage("contactSection")}
            className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 font-heading text-white hover:text-gray-200"
          >
            Contact Us
          </Link>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <div className="w-auto hidden lg:flex items-center">
            <Link
              className="flex justify-between  w-full py-1 px-1  text-center font-heading font-medium text-base text-white  border-none rounded-sm transition  ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300"
              to="https://www.youtube.com/c/ForexSalary/?sub_confirmation=1"
              target="0"
              onClick={() => setMobileMenuOpen(false)}
            >
              <div className="flex-auto m-auto pr-2">Youtube Channel</div>
              
              <div className="flex-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-15 w-12"
                fill="currentColor"
                style={{ color: "#ff0000" }}
                viewBox="0 0 24 24"
              >
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
              </svg>
              </div>
            </Link>
          </div>
     {/* Mobile nav bar  */}
          <div className="w-auto lg:hidden ">
            <Link
              className="navbar-burger inline-flex w-14 h-14 justify-center items-center bg-sky-500 hover:bg-sky-500 rounded-full"
              to="/"
            >
              <svg
                width="20"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 9H1M19 1H1"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </nav>
 
      <Dialog
        as="div"
        className="lg:hidden bg-forex-10"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10 bg-forex-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-forex-10 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between bg-forex-10 -mx-2  px-3 py-2">
            <Link
              to="/"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-1.5 p-1.5"
            >
              <span className="sr-only">Forex Salary</span>
              <img
                className="h-12 w-auto"
                src="logo.png"
                alt="mobile forex salary logo"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root bg-forex-10">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to="/aboutme"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-900"
                >
                  About Me
                </Link>
                <Link
                  to="/youtube"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-900"
                >
                  Youtube
                </Link>
                <Link
                  to="/podcast"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-900"
                >
                  Podcast
                </Link>
                <Disclosure as="div" className="-mx-3">
                  {({ open, close }) => (
                    <>
                      <Disclosure.Button
                        className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white hover:bg-gray-900"
                        // onClick={open ? close : undefined}
                      >
                        Forex Trading
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...forexTrading].map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            onClick={() => {
                              close();
                              setMobileMenuOpen(false);
                            }}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-white hover:bg-gray-900"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

                <Link
                  to="/"
                  onClick={() => {
                    scrollToPage("contactSection");
                    setMobileMenuOpen(false);
                  }}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-900"
                >
                  Contact Us
                </Link>
                <Disclosure as="div" className="-mx-3">
                  {({ open, close }) => (
                    <>
                      <Disclosure.Button
                        className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white hover:bg-gray-900"
                        // onClick={open ? close : undefined}
                      >
                        Brokers
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...brokers].map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            onClick={() => {
                              close();
                              setMobileMenuOpen(false);
                            }}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-white hover:bg-gray-900"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  {({ open, close }) => (
                    <>
                      <Disclosure.Button
                        className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white hover:bg-gray-900"
                        // onClick={open ? close : undefined}
                      >
                        Trading Tools
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...tradingTools].map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            onClick={() => {
                              close();
                              setMobileMenuOpen(false);
                            }}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-white hover:bg-gray-900"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>

              <div className="w-full">
                <Link
                  className="block w-full py-4 px-4 mb-8 text-center font-heading font-medium text-base text-white border rounded-sm transition duration-200 border-sky-600 hover:border-sky-600 bg-sky-600 hover:bg-sky-600"
                  to="/register"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Youtube Channel
                </Link>

                <p className="pl-2 text-sm">2024 © Forex Salary</p>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
