import Aos from "aos";
import React, { useEffect } from "react";

export default function YoutubeSection() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div className="pt-5">
      <div className="w-full mx-auto bg-transparent rounded-xl shadow-md overflow-hidden">
        <div className="md:flex gap-4">
          <div className="md:w-1/2 bg-transparent card" data-aos="fade-up-right">
            <iframe
              title="Video 1"
              className="w-full aspect-video m-1 bg-transparent  rounded-3xl"
              src="https://www.youtube.com/embed/br-RL0MO-dY?si=akyANZxbn9Hj5Evl"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="md:w-1/2 bg-transparent card" data-aos="fade-up-left">
            <iframe
              title="Video 2"
              className="w-full aspect-video m-1 bg-transparent  rounded-3xl"
              src="https://www.youtube.com/embed/gT56u8_KB3A?si=XMFajO72TZjpUgbU"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
