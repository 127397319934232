import React from 'react'
import { Helmet } from "react-helmet";


export default function ResourcesHelmet() {
  return (
    <div><Helmet>
    <title>Download Resource</title>
    <meta
      name="description"
      content="Our comprehensive collection of downloadable resources designed to enhance your Forex trading experience and equip you with powerful tools for success in the dynamic world of currency trading."
    />
  </Helmet></div>
  )
}
