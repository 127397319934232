import Aos from "aos";
import React, { useEffect } from "react";

export default function ActBanner() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div className="container">
      <section className="relative py-6 sm:py-12 ">
        <div className="absolute top-0 left-0 w-full h-full opacity-40"></div>
        <div className="relative z-10 gap-5 items-center lg:flex">
          <div
            className="flex-1 max-w-lg py-5 sm:mx-auto sm:text-center lg:max-w-max lg:text-left"
            data-aos="fade-right"
          >
            <div className="mt-5 px-4 py-2 text-sky-600 font-medium bg-sky-50 rounded-full inline-flex items-center">
              Acknowledgement and Agreement
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 ml-1 duration-150"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
            </div>

            <h3 className="text-3xl text-white font-semibold md:text-4xl">
              Navigating Financial
              <br />
              <span className="text-sky-500 text-2xl md:text-3xl">
                Markets with Confidence
              </span>
            </h3>
            <p className="text-white leading-relaxed mt-3">
              Welcome to Forex Salary Connect, your gateway to the dynamic world
              of financial markets. Our platform offers valuable insights,
              signals, education, and information to empower users in their
              trading journey. As you explore our website and services, it's
              important to acknowledge and understand the risks associated with
              trading and investing.
            </p>
          </div>
          <div
            className="flex-1 mt-5 mx-auto sm:w-9/12 lg:mt-0 lg:w-auto hover:shadow-[0px_10px_20px_rgba(225,_212,_247,_1)]"
            data-aos="fade-left"
          >
            <img
              src="/images/Acknowledgement and Agreement.webp"
              alt="banner about us page"
              className="w-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
