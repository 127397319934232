import React from "react";
import BookBanner from "./BookBanner";
import BookList from "./BookList";
import ForexBookHelmet from "../Helmets/ForexBookHelmet";
import Partner from "../Homepage/Partner";

export default function Index() {
  return (
    <div>
      <div className="container">
        <ForexBookHelmet />
        <BookBanner />
        <BookList />
        {/* <Partner /> */}
      </div>
    </div>
  );
}
