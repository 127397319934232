import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import YoutubeSkelton from "../Alerts/YoutubeSkelton";
import Snackbar from "../Alerts/Snackbar";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Aos from "aos";

export default function BookList() {
  const [bookDetails, setBookDetails] = useState([]);
  // const [resposeData, setResposeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const res = await axiosClient.get("/api/v1/book/booklist");
      setBookDetails(res.data);
      // setResposeData(res.data);
      // console.log(res.data);
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);

  return (
    <div>
      {isLoading && <YoutubeSkelton />}
      {!!error ? (
        <Snackbar message={error} type="failed" />
      ) : (
        <div className="mb-4 grid grid-cols-1 gap-4 sm:grid-cols-3 md:mb-8 md:grid-cols-4 md:gap-6 xl:gap-8">
          {bookDetails.map((card, index) => (
            <div
              key={index}
              className="group relative flex items-end overflow-hidden rounded-lg hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]"
              data-aos="fade-down-left"
            >
              <Card className="w-96 bg-transparent" >
                <CardHeader
                  shadow={false}
                  floated={false}
                  className="h-100 pt-1 px-10 bg-transparent"
                >
                  <img
                    src={card.bookLogo}
                    alt={card.title}
                    className="h-full w-full object-cover hover:scale-[1.02] focus:scale-[1.02] active:scale-100 rounded-lg"
                  />
                </CardHeader>
                <CardBody className="px-6 py-2 bg-transparent">
                  <div className="mb-0 flex items-center justify-between text-white">
                    <Typography color="blue-gray" className="font-small">
                      {card.bookType}
                    </Typography>
                    <Typography color="blue-gray" className="font-small">
                      {card.onlineType}
                    </Typography>
                  </div>
                </CardBody>
                <CardFooter className="m-4 px-2 py-0">
                  <Link
                    to={card.buyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      size="sm"
                      className="btn-forex"
                      ripple={false}
                      fullWidth={true}
                    >
                      Buy Now
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
