import React from "react";

export default function CardSkelton() {
  return (
    <div>
      <div className="card border bg-forex-1 p-5">
        <div className="h- flex animate-pulse">
          <div className="flex-shrink-0">
            <span className="block h-12 w-12 rounded-full bg-gray-100 "></span>
          </div>
          <div className="ms-4 mt-2 w-full">
            <h3 className="w-1/4  h-5 rounded-full text-sm bg-gray-100 text-gray-100 "> Loading</h3>
            <ul className="mt-5 space-y-3">
              <li className="h-4 w-full rounded-full bg-gray-100 "></li>
              <li className="h-4 w-full rounded-full bg-gray-100 "></li>
              <li className="h-4 w-full rounded-full bg-gray-100 "></li>
              <li className="h-4 w-full rounded-full bg-gray-100 "></li>
              <li className="h-4 w-full rounded-full bg-gray-100 "></li>
              <li className="h-4 w-full rounded-full bg-gray-100 "></li>
              <li className="h-4 w-full rounded-full bg-gray-100 "></li>
              <li className="h-4 w-full rounded-full bg-gray-100 "></li>
              <li className="h-4 w-full rounded-full bg-gray-100 "></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
