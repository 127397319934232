import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function SocialBar() {
  return (
    <div>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
        />
      </Helmet>
      <div className="icon-bar">
        <Link to="https://www.facebook.com/profile.php?id=100042874849249" target="_blank" className="facebook">
          <i className="fa-brands fa-facebook-messenger" />
        </Link>
        <Link to="https://www.instagram.com/forex.salary/" target="_blank" className="instagram">
          <i className="fa-brands fa-instagram" />
        </Link>
        <Link to="mailto:forexsalary.India@gmail.com" target="_blank" className="google">
          <i className="fa-solid fa-envelope" />
        </Link>
      </div>
    </div>
  );
}
