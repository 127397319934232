import Aos from "aos";
import React, { useEffect } from "react";

export default function TechnicalSignals() {
  const technicalSignals = [
    {
      id: 1,
      title: "technical signal 1",
      animation:"fade-up-right",
      embedUrl:
        "https://www.tradingview-widget.com/embed-widget/technical-analysis/?locale=en#%7B%22interval%22%3A%2215m%22%2C%22width%22%3A225%2C%22isTransparent%22%3Afalse%2C%22height%22%3A400%2C%22symbol%22%3A%22FX%3AEURUSD%22%2C%22showIntervalTabs%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22technical-analysis%22%2C%22page-uri%22%3A%22forexsalaryconnect.com%2F%3Fpage_id%3D1249%22%7D",
    },
    {
      id: 2,
      title: "technical signal 2",
      animation:"fade-up-left",
      embedUrl:
        "https://www.tradingview-widget.com/embed-widget/technical-analysis/?locale=en#%7B%22interval%22%3A%221m%22%2C%22width%22%3A225%2C%22isTransparent%22%3Afalse%2C%22height%22%3A400%2C%22symbol%22%3A%22FX%3AGBPUSD%22%2C%22showIntervalTabs%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22technical-analysis%22%2C%22page-uri%22%3A%22forexsalaryconnect.com%2F%3Fpage_id%3D1249%22%7D",
    },
    {
      id: 3,
      title: "technical signal 3",
      animation:"fade-up-right",
      embedUrl:
        "https://www.tradingview-widget.com/embed-widget/technical-analysis/?locale=en#%7B%22interval%22%3A%221m%22%2C%22width%22%3A225%2C%22isTransparent%22%3Afalse%2C%22height%22%3A400%2C%22symbol%22%3A%22FX%3AAUDUSD%22%2C%22showIntervalTabs%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22technical-analysis%22%2C%22page-uri%22%3A%22forexsalaryconnect.com%2F%3Fpage_id%3D1249%22%7D",
    },
    {
        id: 4,
        title: "technical signal 4",
        animation:"fade-up-left",
        embedUrl:
          "https://www.tradingview-widget.com/embed-widget/technical-analysis/?locale=en#%7B%22interval%22%3A%221m%22%2C%22width%22%3A225%2C%22isTransparent%22%3Afalse%2C%22height%22%3A400%2C%22symbol%22%3A%22FX%3ANZDUSD%22%2C%22showIntervalTabs%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22technical-analysis%22%2C%22page-uri%22%3A%22forexsalaryconnect.com%2F%3Fpage_id%3D1249%22%7D",
      },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div className="py-8">
       <h3 className="text-xl rounded-md pl-2 py-5 bg-sky-600 text-center text-white font-semibold mb-2">
              Technical Signals
            </h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        {technicalSignals.map((technicalTools) => (
          <div
            key={technicalTools.id}
            className="border p-1 rounded-md bg-transparent shadow-lg"
            data-aos={technicalTools.animation}
          >
            {/* <h3 className="text-xl font-semibold mb-2">{technicalTools.title}</h3> */}
            <iframe
              title={`technicalTools ${technicalTools.id}`}
              src={technicalTools.embedUrl}
              height={400}
              className="w-full rounded-md"
              allowtransparency="true"
              lang="en"
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}
