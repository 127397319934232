import Footer from "../Navigation/Footer";
import Header from "../Navigation/Header";
import ScrollToTop from "react-scroll-to-top";
import { ReactComponent as MySVG } from "./Artboard 1.svg";
import SocialBar from "./SocialBar";

const Layout = ({ children }) => {
  return (
    <>
      <div className="header">
        <Header />
      </div>
      <main className="w-full">
        <div>{children}</div>
      </main>
      <ScrollToTop smooth component={<MySVG />} />
      <SocialBar />
      <div className="footer">
        <Footer />
      </div>
    </>
  );
};

export default Layout;
