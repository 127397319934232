import React from "react";
import { Helmet } from "react-helmet";

export default function FundingFirmHelmet() {
  return (
    <div>
      <Helmet>
        <title>Funding Firms</title>
        <meta
          name="description"
          content="Explore a selection of trusted prop trading firms willing to fund your trades while sharing in the profits. Maximize this opportunity by opening an account through our link, which grants you access to exclusive discounts and personalized assistance."
        />
      </Helmet>
    </div>
  );
}
