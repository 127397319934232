import React, { useEffect } from "react";
import DisclaimerBanner from "./components/DisclaimerBanner";
import Aos from "aos";

export default function Disclaimer() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div>
      <DisclaimerBanner />
      <section className="container">
        <blockquote data-aos="fade-down-right" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "Trading foreign exchange on margin carries a high level of risk and
            may not be suitable for all investors. The high degree of leverage
            can work both in favor of and against you. Before deciding to invest
            in foreign exchange, it is crucial to carefully consider your
            investment objectives, level of experience, and risk appetite."
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-left" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "The possibility exists that you could sustain a loss of some or all
            of your initial investment. It is important to recognize that if the
            market moves against you, you may sustain a total loss greater than
            the amount you initially deposited into your account. Therefore, it
            is imperative that you do not engage in trading unless you fully
            understand the nature of the transactions you are entering into and
            the extent of your exposure to loss."
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-right" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "By utilizing the Forex Salary Connect website and other services
            provided, you explicitly agree not to hold Forex Salary Connect, or
            any of its affiliates, liable for decisions based on information
            contained in blog posts, reader responses to blog posts, or
            information anywhere else on their website or in promotional
            material."
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-left" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "Furthermore, it is essential to understand that trading foreign
            exchange involves significant financial risk and is not suitable for
            everyone. It requires a deep understanding of market dynamics,
            technical analysis, and risk management principles. Individuals
            should only invest funds that they can afford to lose without
            affecting their financial stability or lifestyle."
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-right" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            Additionally, traders should be aware of the following risks
            associated with foreign exchange trading:
            <ul class="space-y-1 text-gray-50 list-outside">
              <li>
                <span className="text-sky-600">Volatility:</span> The foreign
                exchange market is highly volatile and subject to rapid price
                movements, which can result in significant gains or losses in a
                short period.
              </li>
              <li>
                <span className="text-sky-600">Leverage: </span>Trading on
                margin amplifies both potential profits and losses. While
                leverage can magnify gains, it also increases the risk of
                substantial losses, potentially exceeding the initial
                investment.
              </li>
              <li>
                <span className="text-sky-600">Market Conditions: </span>Market
                conditions can change rapidly due to various factors such as
                economic indicators, geopolitical events, and central bank
                decisions, leading to unpredictable price movements.
              </li>
              <li>
                <span className="text-sky-600">Lack of Regulation: </span>The
                foreign exchange market operates globally and is decentralized,
                which may expose traders to risks associated with limited
                regulatory oversight in certain jurisdictions.
              </li>
              <li>
                <span className="text-sky-600">
                  Technical and Operational Risks:{" "}
                </span>
                Trading platforms, internet connectivity, and software glitches
                may impact the execution of trades and result in losses.
              </li>
              <li>
                <span className="text-sky-600">Psychological Factors: </span>
                Emotions such as fear, greed, and overconfidence can influence
                trading decisions and lead to irrational behavior, potentially
                exacerbating losses.
              </li>
            </ul>
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-left" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "It is strongly recommended that individuals seeking to trade
            foreign exchange markets seek advice from an independent financial
            advisor if they have any doubts or concerns regarding their
            investment strategy or risk tolerance."
          </p>
        </blockquote>
        <blockquote data-aos="fade-down-right" className="p-4 my-4 border-s-4 border-sky-500 bg-transparent ">
          <p className="text-xl italic font-medium leading-relaxed text-white ">
            "By accessing and using our services, you acknowledge and accept the
            risks associated with foreign exchange trading and agree to
            indemnify and hold harmless Forex Salary Connect, its officers,
            employees, and affiliates from any claims, losses, damages,
            liabilities, costs, and expenses arising from your trading
            activities."
          </p>
        </blockquote>
      </section>
    </div>
  );
}
