import React from "react";
import { Helmet } from "react-helmet";

export default function AboutHelmet() {
  return (
    <div>
      <Helmet>
        <title>About Me</title>
        <meta
          name="description"
          content="Kulwant Rana, your trusted forex trading coach in India. Gain insights into my background, expertise, and commitment to helping you succeed in the forex market."
        />
      </Helmet>
    </div>
  );
}
