import React from "react";
import DownloadResources from "./DownloadResources";
import ResourceBanner from "./ResourceBanner";
import ResourcesHelmet from "../Helmets/ResourcesHelmet";
import Partner from "../Homepage/Partner";

export default function Index() {
  return (
    <div className="container">
      <ResourcesHelmet />
      <ResourceBanner />
      <DownloadResources />
      {/* <Partner /> */}
    </div>
  );
}
