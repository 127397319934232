import React from "react";
import IntroVideo from "./IntroVideo";
import ShortIntro from "./ShortIntro";
import SocialPresence from "./SocialPresence";
import FreeToContact from "./FreeToContact";
import Partner from "./Partner";
import Testimonial from "../ForexCourses/Testimonial";
import ActionCourse from "./ActionCourse";
// import HomepageHelmet from "../Helmets/HomepageHelmet";

export default function Index() {
  return (
    <div>
      {/* <HomepageHelmet /> */}
      <IntroVideo />
      {/* <Partner /> */}
      <ShortIntro />
      <SocialPresence />
      <div className="container">
        <ActionCourse />
        <Testimonial />
      </div>
      <FreeToContact />
      
    </div>
  );
}
