import Aos from "aos";
import React, { useEffect } from "react";

export default function CrptoTool() {
  const forexTradingTools = [
    {
      id: 1,
      title: "Trading tool 1",
      height: 40,
      animation: "fade-right",
      embedUrl: "https://bit2me.com/widget/crypto-carousel/v1",
    },
    {
      id: 2,
      title: "Trading tool 2",
      height: 80,
      animation: "fade-left",
      embedUrl:
        "https://www.tradingview-widget.com/embed-widget/tickers/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22Ethereum%22%7D%2C%7B%22description%22%3A%22SOLANA%22%2C%22proName%22%3A%22FTX%3ASOLUSD%22%7D%2C%7B%22description%22%3A%22CARDANO%22%2C%22proName%22%3A%22COINBASE%3AADAUSD%22%7D%2C%7B%22description%22%3A%22XRP%22%2C%22proName%22%3A%22FTX%3AXRPUSD%22%7D%5D%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22showSymbolLogo%22%3Atrue%2C%22width%22%3A%22100%25%22%2C%22height%22%3A104%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22tickers%22%2C%22page-uri%22%3A%22forexsalaryconnect.com%2F%3Fpage_id%3D1281%22%7D",
    },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div>
      <div className="py-8">
        <h3 className="text-xl rounded-md pl-2 py-5 bg-sky-600 text-center text-white  font-semibold mb-2">
          Crypto Trading
        </h3>
        <div className="border p-1 rounded-md bg-transparent shadow-lg mb-2" data-aos="fade-left">
          <coingecko-coin-price-marquee-widget
            coin-ids="bitcoin,eos,ethereum,litecoin,ripple,solana,cardano,shiba-inu"
            currency="usd"
            background-color="#ffffff"
            locale="en"
            vce-ready=""
          />
        </div>
        <div className="grid gap-4 grid-cols-1">
          {forexTradingTools.map((forexTools) => (
            <div
              key={forexTools.id}
              className="border p-1 rounded-md bg-transparent shadow-lg"
              data-aos={forexTools.animation}
            >
              {/* <h3 className="text-xl font-semibold mb-2">{forexTools.title}</h3> */}
              <iframe
                title={`forexTools ${forexTools.id}`}
                src={forexTools.embedUrl}
                className="w-full rounded-md"
                height={forexTools.height}
                allowtransparency="true"
                lang="en"
              ></iframe>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
