import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/layout/Layout.component";
import Home from "./components/Homepage/Index";
import Youtube from "./components/Youtube/Index";
import About from "./components/AboutMe/Index";
import Podcast from "./components/Podcast/Index";
import ForexTool from "./components/ForexTools/Index";
import ForexCalender from "./components/ForexCalendar/Index";
import ForexBook from "./components/ForexBook/Index";
import ForexCourses from "./components/ForexCourses/Index";
import ForexBroker from "./components/ForexBroker/Index";
import BrokerPromotion from "./components/BrokerPromotion/Index";
import FundingFirm from "./components/FundingFirm/Index";
import StockTrading from "./components/StockTrading/Index";
import CryptoTrading from "./components/CryptoTrading/Index";
import Error404 from "./components/Navigation/Error404";
import DownloadResources from "./components/DownloadResources/Index";
import Disclaimer from "./components/Navigation/Disclaimer";
import AckAndAgmt from "./components/Navigation/AckAndAgmt";
import GoogleFormRedirect from "./components/Navigation/GoogleFormRedirect";
import "aos/dist/aos.css";
import "./styles/index.css";
import { initGA, logPageView } from "./analytics";

function App() {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <BrowserRouter>
      <PageTracker />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/youtube" element={<Youtube />} />
          <Route path="/aboutme" element={<About />} />
          <Route path="/podcast" element={<Podcast />} />
          <Route path="/forex-tools" element={<ForexTool />} />
          <Route path="/forex-calendar" element={<ForexCalender />} />
          <Route path="/forex-book" element={<ForexBook />} />
          <Route path="/forex-courses" element={<ForexCourses />} />
          <Route path="/download-resources" element={<DownloadResources />} />
          <Route path="/forex-broker" element={<ForexBroker />} />
          <Route path="/broker-promotion" element={<BrokerPromotion />} />
          <Route path="/funding-firm" element={<FundingFirm />} />
          <Route path="/stock-trading" element={<StockTrading />} />
          <Route path="/crypto-trading" element={<CryptoTrading />} />
          <Route path="/acknowledgement-agreement" element={<AckAndAgmt />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/feedback" element={<GoogleFormRedirect />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  return null;
};

export default App;
