import Aos from "aos";
import React, { useEffect } from "react";

export default function ForexCalculator() {
  const calculators = [
    {
      id: 1,
      title: "Position Size Calculator",
      animation:"fade-up-right",
      embedUrl:
        "https://www.cashbackforex.com/widgets/position-size-calculator?IsDisplayTitle=false&ShowChartLinks=true&TopPaneStyle=YmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KCNmZmYgMjAlLCAjZjVmNWY1IDQ1JSk7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgYm9yZGVyLWJvdHRvbTogbm9uZTsg&BottomPaneStyle=YmFja2dyb3VuZDogI2YzZjNmMzsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgY29sb3I6IGJsYWNrOw==&ButtonStyle=YmFja2dyb3VuZDogIzM0MzU0MDsgY29sb3I6IHdoaXRlOyBib3JkZXItcmFkaXVzOiAyMHB4Ow==&TitleStyle=dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==&TextboxStyle=YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYQ==&MaxWidth=665&ContainerId=position-size-calculator-694067&CompactType=large&HighlightColor=%23ffff00&DefaultInstrument=EUR-USD&IsShowEmbedButton=true",
    },
    {
      id: 2,
      title: "Profit/Loss Calculator",
      animation:"fade-up-left",
      embedUrl:
        "https://www.cashbackforex.com/widgets/profit-calculator?IsDisplayTitle=false&ShowChartLinks=true&TopPaneStyle=YmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KCNmZmYgMjAlLCAjZjVmNWY1IDQ1JSk7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgYm9yZGVyLWJvdHRvbTogbm9uZTsg&BottomPaneStyle=YmFja2dyb3VuZDogI2YzZjNmMzsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgY29sb3I6IGJsYWNrOw==&ButtonStyle=YmFja2dyb3VuZDogIzM0MzU0MDsgY29sb3I6IHdoaXRlOyBib3JkZXItcmFkaXVzOiAyMHB4Ow==&TitleStyle=dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==&TextboxStyle=YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYQ==&MaxWidth=665&ContainerId=profit-calculator-530799&CompactType=large&HighlightColor=%23ffff00&DefaultInstrument=EUR-USD&IsShowEmbedButton=true",
    },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div className="py-8">
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        {calculators.map((calcTools) => (
          <div
            key={calcTools.id}
            className="border p-1 rounded-md bg-transparent shadow-lg"
            data-aos={calcTools.animation}
          >
            <h3 className="text-xl rounded-md pl-2 py-5 bg-sky-600 text-center text-white font-semibold mb-2">{calcTools.title}</h3>
            <iframe
              title={`calcTools ${calcTools.id}`}
              src={calcTools.embedUrl}
              height={630}
              className="w-full rounded-md"
              allowtransparency="true"
              lang="en"
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}
