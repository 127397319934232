import {
  IdentificationIcon,
  SpeakerWaveIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
export const startHere = [
  {
    name: "About me",
    description: "I am Kulwant Rana Founder of Forex Salary.",
    href: "/aboutme",
    icon: IdentificationIcon,
  },
  {
    name: "Youtube",
    description: "Subscribe to our channel for latest Videos.",
    href: "/youtube",
    icon: VideoCameraIcon,
  },
  {
    name: "Podcast",
    description: "Subscribe to our Podcast on all platforms.",
    href: "/podcast",
    icon: SpeakerWaveIcon,
  },
];
