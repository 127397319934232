import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Snackbar from "../Alerts/Snackbar";
import { Card } from "@material-tailwind/react";
import Aos from "aos";
import parse from "html-react-parser";
import CardSkelton from "../Alerts/CardSkelton";
import moment from "moment";

export default function FirmList() {
  const [firmsDetails, setFirmsDetails] = useState([]);
  // const [resposeData, setResposeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        setError(null);
        setIsLoading(true);
        const res = await axiosClient.get("/api/v1/broker/allfund");
        const sortedFirms = res.data.sort(
          (a, b) => moment(b.date) - moment(a.date)
        );
        setFirmsDetails(sortedFirms); 
        console.log(res.data)
      } catch (err) {
        console.log(err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);

  return (
    <div>
      {isLoading && <CardSkelton />}
      {!!error ? (
        <Snackbar message={error} type="failed" />
      ) : (
        <div>
          {firmsDetails.map((card, index) => (
            <Card
              key={index}
              className="w-full p-5 mt-2 bg-transparent shadow-2xl hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]"
            >
              <div className="grid grid-cols-1 gap-0 py-5 rounded-lg md:grid-cols-2 bg-transparent ">
                <div className="flex flex-col" data-aos="zoom-out-right">
                  <div className="rounded-lg h-full">
                    <h2 className="text-2xl font-bold text-gray-50 my-2">
                      {card.fundName}
                    </h2>
                    <div className="flex justify-left w-fit rounded shadow-lg">
                      <img
                        src={card.fundLogoUrl}
                        className=" h-40"
                        alt="tick mill logo"
                        data-aos="flip-left"
                      />
                    </div>

                    <div className="mt-4 text-gray-100">
                      {parse(card.shortDescription)}
                    </div>
                    <p className="text-white my-4">
                      <span className="font-bold">
                        Promotion/Coupon Code :{" "}
                      </span>
                      <span className="bg-sky-700 rounded-lg p-2">
                        {card.promotionCode}
                      </span>
                    </p>
                    <div className="w-auto hidden lg:flex items-center">
                      <a
                        className="block w-fit py-4 px-4 text-center font-heading font-medium text-base text-white border rounded-md transition duration-200 border-sky-600 hover:border-sky-500 bg-sky-600 hover:bg-sky-500"
                        href={card.promotionUrl}
                        target="0"
                      >
                        {card.nameOfButton}
                      </a>
                    </div>
                  </div>
                </div>
                <article
                  className="flex flex-col shadow-none rounded-md h-96 lg:h-96"
                  data-aos="zoom-in-up"
                >
                  <iframe
                    src={card.youtubeVideoUrl}
                    frameborder="0"
                    title={card.fundName}
                    allowfullscreen
                    className="object-cover w-full h-full p-4 rounded-3xl"
                  ></iframe>
                </article>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}
