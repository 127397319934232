import React from "react";
import FirmList from "./FirmList";
import FirmBanner from "./FirmBanner";
import FundingFirmHelmet from "../Helmets/FundingFirmHelmet";
import Partner from "../Homepage/Partner";

export default function Index() {
  return (
    <div className="container">
      <FundingFirmHelmet />
      <FirmBanner />
      <FirmList />
      {/* <Partner /> */}
    </div>
  );
}
