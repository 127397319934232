import React, { useRef } from "react";

export default function IntroVideo() {
  const videoRef = useRef(null);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        // Autoplay was prevented, handle the error
        console.error("Autoplay prevented:", error);
      });
    }
  };

  return (
    <div onClick={playVideo}>
      <header className="relative flex items-center justify-center h-screen overflow-hidden">
        <video
          ref={videoRef}
          poster="./images/aboutme_image.webp"
          autoPlay
          loop
          muted
          playsInline
          className="absolute w-full h-full object-cover"
        >
          <source src="Introduction.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute w-full h-full"></div>
        <div className="container">
          <div className="absolute top-1/2 left-1/5 transform -translate-x-1/5 -translate-y-1/2  font-bold tracking-tight text-black bg-gray-50 bg-opacity-80 p-2 border rounded-md">
            <span className="text-4xl sm:text-6xl heading-font ">
              Kulwant Rana
            </span>
            <br />
            <span className="text-2xl sm:text-4xl heading-cma pt-3">
              Full time Trader & Coach <br /> Qualified CMA & CIMA(Adv Dip MA)
            </span>
          </div>
          <div className="absolute top-1/4 right-0 transform -translate-x-1/5 -translate-y-1/2  font-bold tracking-tight text-black bg-gray-50 bg-opacity-80 p-2 border rounded-md">
            <a
              target="_0"
              href="https://secure.tickmill.com/?utm_campaign=ib_link&utm_content=IB88665532&utm_medium=Account+Types&utm_source=link&lp=https%3A%2F%2Fwww.tickmill.com%2Faccounts%2F"
            >
              <img
                className="h-auto sm:w-40 w-32"
                src="/Official Sponsor.png"
                alt="image description"
              ></img>
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}
