import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

export default function ShortIntro() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);

  return (
    <div >
      <div className="py-10 mx-auto container lg:py-20" data-aos="zoom-in">
        <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
          <div
            className="flex flex-col justify-center"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                Kulwant Rana
              </h2>
              <p className="text-base text-white md:text-lg font-bold">
                Full time Trader & Coach Qualified CMA & CIMA(Adv Dip MA) <br />
                Founder “FOREXSALARY” & “FOREXSALARYCONNECT”
              </p>
            </div> 
            <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
              <div className=" border-l-4 shadow-sm border-sky-600 hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]">
                <div className="h-full p-5 border border-l-0 rounded-r">
                  <h6 className="mb-2 font-semibold leading-5 text-sky-500">
                    When I decided to embark on my journey to become a
                    professional Forex Trader,
                  </h6>
                  <p className="text-white">
                    I had to face many challenges as no clear guidance and
                    mentoring was available at that time. It took me 4 years to
                    understand market concepts and become successful in pursuing
                    Forex Trading.
                  </p>
                </div>
              </div>
              <div className=" border-l-4 shadow-sm border-sky-600 hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]">
                <div className="h-full p-5 border border-l-0 rounded-r">
                  <h6 className="mb-2 font-semibold leading-5 text-sky-500">
                    In order to provide the guidance you need and inspire other
                    traders,
                  </h6>
                  <p className="text-white">
                    I started the “Forex Salary” YouTube channel & Podcast
                    “Forex salary connect “to pass my knowledge so you can make
                    career in Forex world.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="700">
            <img
              className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
              src="./images/founder.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
