import React, { useState, useEffect } from "react";

const Snackbar = ({ message, type }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleClose();
    }, 5000); // Auto hide after 5 seconds

    return () => {
      clearTimeout(timeoutId); // Clear the timeout if component unmounts
    };
  },);

  const snackbarClasses = `flex items-center justify-center mt-2 p-2 transition-opacity duration-300 ${
    isVisible
      ? "opacity-100 pointer-events-auto"
      : "opacity-0 pointer-events-none"
  }`;

  const snackbarContentClasses = `bg-white rounded-lg shadow-md p-2 border ${
    type === "success"
      ? "border-sky-500"
      : type === "error"
      ? "border-red-500"
      : "border-blue-500"
  }`;

  return (
    <div className={snackbarClasses}>
      <div className={snackbarContentClasses}>
        <div className="flex items-center">
          {type === "success" && (
            <div className="w-8 h-8 bg-sky-500 text-white flex items-center justify-center rounded-full mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="check-circle w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          )}
          {type === "failed" && (
            <div className="w-8 h-8 bg-red-500 text-white flex items-center justify-center rounded-full mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="x-circle w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          )}
          {type === "info" && (
            <div className="w-8 h-8 bg-blue-500 text-white flex items-center justify-center rounded-full mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="information-circle w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
                />
              </svg>
            </div>
          )}
          <div>
            <p className="text-sm text-green-900">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Snackbar;
