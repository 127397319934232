import Aos from "aos";
import React, { useEffect } from "react";

export default function TickerTape() {
  const technicalSignals = [
    {
      id: 1,
      title: "Forex Calendar",
      animation:"zoom-in-left",
      embedHeight: 400,
      embedUrl:
        "https://www.cashbackforex.com/widgets/economic-calendar?ContainerId=economic-calendar-590021&DefaultTime=today&IsShowEmbedButton=true&DefaultTheme=plain",
    },
    {
      id: 2,
      title: "Currency Correlation",
      animation:"zoom-in-right",
      embedHeight: 400,
      embedUrl:
        "https://widgets.myfxbook.com/widgets/market-correlation.html?rowSymbols=8,47,9,10,1234,11,103,12,46,1245,6,13,14,15,17,18,7,2114,19,20,21,22,1246,23,1,1233,107,24,25,4,2872,137,48,1236,1247,2012,2,1863,3240,26,49,27,28,2090,131,5,29,5779,31,34,3,36,37,38,2076,40,41,42,43,45,3005,3473,50,2115,2603,2119,1815,2521,51,5435,5079,1893&colSymbols=8,9,10,6,7,1,4,2,5,3&timeScale=1440",
    },
    {
      id: 3,
      title: "Traders Sentiment",
      animation:"zoom-in-left",
      embedHeight: 400,
      embedUrl:
        "https://widgets.myfxbook.com/widgets/outlook.html?type=1&symbols=,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,31,32,33,34,36,37,38,40,41,42,43,45,46,47,48,49,50,51,103,107,129,131,136,137,1209,1233,1234,1235,1236,1245,1246,1247,1249,1252,1253,1259,1260,1327,1692,1694,1773,1778,1781,1806,1815,1816,1863,1864,1893,1959,1965,2012,2076,2090,2099,2103,2114,2115,2119,2326,2348,2438,2482,2511,2516,2519,2521,2603,2694,2729,2872,3001,3005,3240,3304,3473,3771,3887,4845,4963,5079,5281,5435,5539,5779,5851,5858,5879,6106,8397,8669,8686,8895,8899,9657,9667,10064,12755,13517,14216,14247,17184,19780,20010,34882,54505,69230,79789,123633,177761,356365,356944,356945,367960,397724",
    },
    {
        id: 4,
        title: "Country Bank Interest Rate",
        animation:"zoom-in-right",
        embedHeight: 400,
        embedUrl:
          "https://freeserv.dukascopy.com/2.0/?path=interest_rate_calendar/index&showHeader=false&tableBorderColor=%23D92626&delta=true&changed=true&nameType=1&width=100%25&height=90%25&adv=popup&lang=en",
      },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return ( 
    <div className="py-8 container">
      <div className="grid gap-4 grid-cols-1">
        {technicalSignals.map((tickerTape) => (
          <div
            key={tickerTape.id}
            className="border p-1 rounded-md bg-transparent shadow-lg"
            data-aos={tickerTape.animation}
          >
            <h3 className="text-xl rounded-md pl-2 py-5 bg-sky-600 text-center text-white font-semibold mb-2">
              {tickerTape.title}
            </h3>

            <iframe
              title={`tickerTape ${tickerTape.id}`}
              src={tickerTape.embedUrl}
              height={tickerTape.embedHeight}
              className="w-full min-h-fit rounded-md"
              allowtransparency="true"
              lang="en"
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}
