import React from 'react'
import { Helmet } from "react-helmet";


export default function ForexCourseHelmet() {
  return (
    <div>
      <Helmet>
        <title>Forex Courses</title>
        <meta
          name="description"
          content="Our Forex Price Action Monthly Course is meticulously designed for individuals like you who are committed to advancing their trading skills and achieving success in the forex market."
        />
      </Helmet>
    </div>
  )
}
