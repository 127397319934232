import Aos from "aos";
import React, { useEffect } from "react";

export default function ForexNews() {
  const forexNews = [
    {
      id: 1,
      title: "Forex Latest News",
      animation:"fade-up-right",
      embedHeight:390,
      embedUrl: "https://www.dailyforex.com/forex-widget/widget/41630",
    },
    {
      id: 2,
      title: "Forex Market Sessions",
      animation:"fade-up-left",
      embedHeight:400,
      embedUrl: "https://widget.myfxbook.com/widget/market-hours.html",
    },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div className="py-8">
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        {forexNews.map((newsForex) => (
          <div
            key={newsForex.id}
            className="border p-1 rounded-md bg-transparent shadow-lg"
            data-aos={newsForex.animation}
          >
            <h3 className="text-xl rounded-md pl-2 py-5 bg-sky-600 text-center text-white font-semibold mb-2">{newsForex.title}</h3>
           
            <iframe
              title={`newsForex ${newsForex.id}`}
              src={newsForex.embedUrl}
              height={newsForex.embedHeight}
              className="w-full rounded-md"
              allowtransparency="true"
              lang="en"
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}
