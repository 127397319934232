import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import CourseSvg from "./CourseSvg";
import CourseStructure from "./CourseStructure";
import Snackbar from "../Alerts/Snackbar";
import Aos from "aos";

const CourseContents = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
    location: "",
    exprience: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    let errorMessage = "";
    if (name === "email") {
      if (value.trim() !== "" && !validateEmail(value)) {
        errorMessage = "Please enter a valid email address.";
      }
    } else if (name === "phone") {
      if (value.trim() !== "" && !validatePhone(value)) {
        errorMessage = "Please enter a valid 10-digit phone number.";
      }
    }
    setError(errorMessage);
    setFormData((state) => ({ ...state, [name]: value }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const onSubmintHandler = async (event) => {
    event.preventDefault();
    setError("");
    try {
      setIsSubmitting(true);

      // Input validation
      if (
        !formData.fullName ||
        !formData.email ||
        !formData.phone ||
        !formData.location ||
        formData.exprience === ""
      ) {
        throw new Error("Please fill all input fields.");
      }

      // Email validation
      if (!validateEmail(formData.email)) {
        throw new Error("Please enter a valid email address.");
      }

      // Phone validation
      if (!validatePhone(formData.phone)) {
        throw new Error("Please enter a valid 10-digit phone number.");
      }

      const res = await axiosClient.post(
        "/api/v1/mail/coursecontact",
        formData
      );
      setIsSuccess(true);
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        message: "",
        location: "",
        exprience: "",
      });
      console.log(res.data.message);
      setSuccessMessage(res.data.message);
    } catch (err) {
      console.error(err);
      setError(err?.response?.data?.message || "An error occurred.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <section className="bg-transparent py-5 overflow-hidden relative z-10">
        <div className="">
          <div className="flex flex-wrap mx-4 lg:justify-between">
            <div className="w-full lg:w-1/2 xl:w-6/12">
              <CourseStructure />
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <p className="mb-6 text-dark text-xl pt-0 sm:pt-20">
                Course Inquiry
              </p>
              <div
                className="relative p-8 bg-gray-50 rounded-lg shadow-lg sm:p-12"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                {!!error && <Snackbar message={error} type="failed" />}
                {!!isSuccess && (
                  <Snackbar
                    message={successMessage}
                    type="success"
                  />
                )}
                <form onSubmit={onSubmintHandler}>
                  <div className="py-2">
                    <span className="px-1 pt-2 mb-2 text-lg font-bold text-gray-600">
                      Your Name
                    </span>
                    <input
                      type="text"
                      placeholder="Enter your name"
                      name="fullName"
                      className="text-md block px-3 py-2 text-gray-900 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      value={formData.fullName}
                      onChange={onChangeHandler}
                      required
                    />
                  </div>
                  <div className="py-2">
                    <span className="px-1 pt-2 mb-2 text-lg font-bold text-gray-600">
                      Email
                    </span>
                    <input
                      type="text"
                      name="email"
                      placeholder="Your email id"
                      className="text-md block px-3 py-2 text-gray-900 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      value={formData.email}
                      onChange={onChangeHandler}
                      required
                    />
                  </div>
                  <div className="py-2">
                    <span className="px-1 pt-2 mb-2 text-lg font-bold text-gray-600">
                      Phone Number
                    </span>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Your phone number"
                      className="text-md block px-3 py-2 text-gray-900 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      value={formData.phone}
                      onChange={onChangeHandler}
                      required
                    />
                  </div>
                  <div className="py-2">
                    <span className="px-1 pt-2 mb-2 text-lg font-bold text-gray-600">
                      Location
                    </span>
                    <input
                      type="text"
                      placeholder="Location"
                      name="location"
                      className="text-md block px-3 py-2 text-gray-900 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      value={formData.location}
                      onChange={onChangeHandler}
                      required
                    />
                  </div>
                  <div className="py-2">
                    <span className="px-1 pt-2 mb-2 text-lg font-bold text-gray-600">
                      Exprience
                    </span>
                    <input
                      placeholder="Trading Experience(In Months)"
                      type="number"
                      name="exprience"
                      className="text-md block px-3 py-2 text-gray-900 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      value={formData.exprience}
                      onChange={onChangeHandler}
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <span className="px-1 pt-2 mb-2 text-lg font-bold text-gray-600">
                      Message
                    </span>
                    <textarea
                      rows="6"
                      placeholder="Enter your questions or suggestions."
                      type="text"
                      id="Message"
                      name="message"
                      className="text-md block px-3 py-2 text-gray-900 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      value={formData.message}
                      onChange={onChangeHandler}
                      required
                    />
                  </div>
                  <div className="animate-fade-right animate-once animate-duration-1000 animate-delay-1000 animate-ease-linear pt-2">
                    <button 
                      type="submit"
                      className="flex items-center bg-sky-500 text-white gap-1 px-4 py-2 cursor-pointer font-semibold tracking-widest rounded-md hover:bg-sky-600 duration-300 hover:gap-2 hover:translate-x-3"
                    >
                      {isSubmitting ? "Sending..." : "Send"}
                      <svg
                        className="w-5 h-5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                          stroke-linejoin="round"
                          stroke-linecap="round"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </form>
                <CourseSvg />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseContents;
