import {
    ArrowTrendingUpIcon,
    CurrencyDollarIcon,
    ArrowDownTrayIcon,
    UsersIcon,
    MegaphoneIcon,
    CurrencyRupeeIcon,
} from "@heroicons/react/24/outline";

export const tradingTools = [
    {
        name: "Stock Trading",
        description: "Stock trading tools are available here.",
        href: "/stock-trading",
        icon: ArrowTrendingUpIcon,
    },
    {
        name: "Crypto Trading",
        description: "Crypto trading tools are available here.",
        href: "/crypto-trading",
        icon: CurrencyDollarIcon,
    },
    {
        name: "Resources",
        description: "Download free Material from our community.",
        href: "/download-resources",
        icon: ArrowDownTrayIcon,
    },
];

export const brokers = [
    {
        name: "Forex Brokers",
        description: "Estimate your monthly loan payments.",
        href: "/forex-broker",
        icon: UsersIcon,
    },
    {
        name: "Brokers Promotion",
        description: "Estimate your monthly loan payments.",
        href: "/broker-promotion",
        icon: MegaphoneIcon,
    },
    {
        name: "Funding Firms",
        description: "Estimate your monthly loan payments.",
        href: "/funding-firm",
        icon: CurrencyRupeeIcon,
    },
];
