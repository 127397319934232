import React from "react";
import CoursesBanner from "./CoursesBanner";
import CourseContents from "./CourseContents";
import Testimonial from "./Testimonial";
import Feedback from "./Feedback";
import ForexCourseHelmet from "../Helmets/ForexCourseHelmet";
import Partner from "../Homepage/Partner";

export default function Index() {
  return (
    <div className="container">
      <ForexCourseHelmet />
      <CoursesBanner />
      <CourseContents />
      <Testimonial />
      <Feedback />
      {/* <Partner /> */}
    </div>
  );
}
