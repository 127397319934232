import React from "react";
import CrptoTool from "./CrptoTool";
import TickerTape from "./TickerTape";
import TechnicalSignals from "./TechnicalSignals";
import CryptoNews from "./CryptoNews";
import CryptoTradingHelmet from "../Helmets/CryptoTradingHelmet";
import Partner from "../Homepage/Partner";

export default function Index() {
  return (
    <div className="container">
      <CryptoTradingHelmet />
      <CrptoTool />
      <TickerTape />
      <TechnicalSignals />
      <CryptoNews />
      {/* <Partner /> */}
    </div>
  );
}
