import Aos from "aos";
import React, { useEffect } from "react";

export default function MyCertificate() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div>
      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <header className="text-left">
            <h1 className="my-2 text-2xl font-semibold text-gray-50 md:text-3xl ">
              My Certificates
            </h1>
          </header>
          <ul>
          <li data-aos="zoom-out-right ">
            <div className="group relative block hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]">
              <img
                src="./images/verified-image.jpg"
                alt="the 5%ers level 2"
                className=" aspect-auto  w-full object-cover transition duration-500 group-hover:opacity-90"
              />
              <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                <span className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                  The 5%ers
                </span>
              </div>
            </div>
          </li>
          </ul>
          <ul className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
            <li
              data-aos="zoom-out-right"
              className="hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]"
            >
              <div className="group relative block">
                <img
                  src="./images/verified-image1.jpg"
                  alt="the 5%ers level 2"
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <span className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                    The 5%ers
                  </span>
                </div>
              </div>
            </li>

            {/* <li data-aos="zoom-out-right">
              <div className="group relative block">
                <img
                  src="./images/verified-image1.jpg"
                  alt="5percentier logo"
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">

                  <span className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                    The 5%ers
                  </span>
                </div>
              </div>
            </li> */}

            <li
              className="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1 hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]"
              data-aos="zoom-in-left"
            >
              <div className="group relative block">
                <img
                  src="./images/payout_5percent.png"
                  alt="The 5%ers"
                  className="aspect-auto w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <span className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                    The 5%ers
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}
