import React, { useCallback, useEffect, useRef, useState } from "react";
import "swiper/css";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from "swiper/react";
import DotShape from "./DotShape";
import { Autoplay } from "swiper/modules";
import moment from "moment";
import axiosClient from "../../axios-client";
import Aos from "aos";
import YoutubeSkelton from "../Alerts/YoutubeSkelton";
import Snackbar from "../Alerts/Snackbar";

export default function Testimonial() {
  const [reviewDetails, setReviewDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); 

  const getData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const res = await axiosClient.get("/api/v1/review/reviewlist");
      const sortedReview = res.data.sort((a, b) => moment(b.time) - moment(a.time));
      setReviewDetails(sortedReview);
      // console.log(sortedReview)
    } catch (err) {
      // console.log(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div>
      {isLoading && <YoutubeSkelton />}
      {!!error ? (
        <Snackbar message={error} type="failed" />
      ) : (
        <section>
        <h3 className="text-3xl pt-10 text-white font-semibold md:text-4xl">
          Testimonial :{" "}
          <span className="text-sky-500">
            What others say about this course!
          </span>
        </h3>
        <section className="pt-10 pb-10">
          <div className="container mx-auto">
            <Swiper
              slidesPerView={1}
              ref={sliderRef}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
            >
              {reviewDetails.map((testimonial) => (
                <SwiperSlide key={testimonial._id}>
                  <div className="relative flex justify-center ">
                    <div className="relative w-full pb-16 md:w-11/12 lg:w-10/12 xl:w-8/12 xl:pb-0">
                      <div className="w-full items-center md:flex ">
                        <div className="relative mb-12 w-full max-w-[310px] md:mr-12 md:mb-0 md:max-w-[250px] lg:mr-14 lg:max-w-[280px] 2xl:mr-16 hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]">
                          <img
                            src={testimonial.reviewerPhoto}
                            className="w-full"
                            alt="testimonial"
                          />
                          <span className="absolute -top-6 -left-6 z-[-1] hidden sm:block">
                            <DotShape />
                          </span>
                          <span className="absolute -bottom-6 -right-6 z-[-1]">
                            <svg
                              width="64"
                              height="64"
                              viewBox="0 0 64 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 32C3 15.9837 15.9837 3 32 3C48.0163 2.99999 61 15.9837 61 32C61 48.0163 48.0163 61 32 61C15.9837 61 3 48.0163 3 32Z"
                                stroke="#13C296"
                                strokeWidth="6"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="w-full">
                          <div>
                            <p className="text-body-color mb-11 text-base font-medium italic sm:text-lg">
                              {testimonial.comment}
                            </p>
                            <h4 className="text-dark text-xl text-center font-semibold lg:text-left">
                              {testimonial.reviewerName}
                            </h4>
                            <p className="text-body-color text-base text-center lg:text-left">
                              {testimonial.designation}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              {/* Back and Next buttons */}
              <div className="absolute z-50 left-0 right-0 bottom-0 flex items-center justify-center lg:pl-[120px] 2xl:pl-0">
                {/* Back button */}
                <div className="prev-arrow cursor-pointer" onClick={handlePrev}>
                  <button className="text-primary hover:bg-sky-800 shadow-input mx-1 flex h-12 w-12 items-center justify-center rounded-full bg-sky-500 transition-all hover:text-white">
                    <svg
                      width="15"
                      height="13"
                      viewBox="0 0 15 13"
                      className="fill-current"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.24264 11.8033L0.46967 7.03037C0.176777 6.73748 0.176777 6.2626 0.46967 5.96971L5.24264 1.19674C5.53553 0.903845 6.01041 0.903845 6.3033 1.19674C6.59619 1.48963 6.59619 1.96451 6.3033 2.2574L2.81066 5.75004H14C14.4142 5.75004 14.75 6.08583 14.75 6.50004C14.75 6.91425 14.4142 7.25004 14 7.25004H2.81066L6.3033 10.7427C6.59619 11.0356 6.59619 11.5104 6.3033 11.8033C6.01041 12.0962 5.53553 12.0962 5.24264 11.8033Z"
                      ></path>
                    </svg>
                  </button>
                </div>
                {/* Next button */}
                <div className="next-arrow cursor-pointer" onClick={handleNext}>
                  <button className="text-primary hover:bg-sky-800 shadow-input mx-1 flex h-12 w-12 items-center justify-center rounded-full bg-sky-500 transition-all hover:text-white">
                    <svg
                      width="15"
                      height="13"
                      viewBox="0 0 15 13"
                      className="fill-current"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.75736 11.8033L14.5303 7.03037C14.8232 6.73748 14.8232 6.2626 14.5303 5.96971L9.75736 1.19674C9.46447 0.903845 8.98959 0.903845 8.6967 1.19674C8.40381 1.48963 8.40381 1.96451 8.6967 2.2574L12.1893 5.75004H1C0.585786 5.75004 0.25 6.08583 0.25 6.50004C0.25 6.91425 0.585786 7.25004 1 7.25004H12.1893L8.6967 10.7427C8.40381 11.0356 8.40381 11.5104 8.6967 11.8033C8.98959 12.0962 9.46447 12.0962 9.75736 11.8033Z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </Swiper>
          </div>
        </section>
        </section>
      )}
    </div>
  );
}
