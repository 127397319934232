import React from "react";
import AboutBanner from "./AboutBanner";
import AboutBody from "./AboutBody";
import AboutHelmet from "../Helmets/AboutHelmet";
import MyCertificate from "./MyCertificate";
import Partner from "../Homepage/Partner";

export default function Index() {
  return (
    <div className="container">
      <AboutHelmet />
      <AboutBanner />
      <AboutBody />
      <MyCertificate />
      {/* <Partner /> */}
    </div>
  );
}
