import Aos from "aos";
import React, { useEffect } from "react";

export default function TickerTape() {
  const technicalSignals = [
    {
      id: 1,
      title: "Market Overview",
      embedHeight: 630,
      animation:"zoom-in-up",
      embedUrl:
        "https://darqube.com/external-embedding/ticker-tape?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3aWRnZXQiOnsiYXNzZXRfY2xhc3NlcyI6WyJGT1JFWCIsIkNPTU1PRElUSUVTIl19LCJ3X3R5cGUiOiJUaWNrZXJUYXBlIiwiZmVfY2ZnIjp7ImNtb2RlIjowLCJmY2xyIjoicmdiYSgyNTUsIDI1NSwgMjU1LCAxKSIsImJnIjoicmdiYSgyMSwgMjUsIDMwLCAxKSIsImgiOjM3MywidyI6MTEwMCwiYXN6Ijp0cnVlLCJmdCI6ImRlZmF1bHQiLCJodCI6Im5vbmUiLCJ0cmgiOltdLCJjaGMiOiJyZ2JhKDIzNywgNTAsIDk4LCAxKSIsImNuIjoiIiwibG5nIjoiZW4iLCJjdHJsdCI6MSwiaGRpY24iOmZhbHNlLCJ3dG1WIjp7InR5cGUiOiJEYXJxdWJlIiwiZW5hYmxlZCI6dHJ1ZX0sImN0cmx2IjoxLCJzbWIiOlt7Im4iOiJFVVIvVVNEIiwidCI6IkVVUlVTRC5PQU5EQSJ9LHsibiI6IkdCUC9VU0QiLCJ0IjoiR0JQVVNELk9BTkRBIn0seyJuIjoiR29sZCIsInQiOiJYQVVVU0QuT0FOREEifSx7Im4iOiJVU0QvSlBZIiwidCI6IlVTREpQWS5PQU5EQSJ9LHsibiI6IkZYQ00gQVVEL1VTRCIsInQiOiJBVURVU0QuRlhDTSJ9LHsibiI6IkZYQ00gVVNEL0NBRCIsInQiOiJVU0RDQUQuRlhDTSJ9LHsibiI6IkZYQ00gTlpEL1VTRCIsInQiOiJOWkRVU0QuRlhDTSJ9XSwib3BuIjp0cnVlfSwiZXhwIjoxNjc1MzQyMTMxLCJzdWIiOiJhY2Nlc3MifQ.AAMRYbM3OW-WoKk5oK8OlbOg8P1ni7x9KU-SQj4xTVA",
    },
    {
      id: 2,
      title: "Currency Strength MAP",
      embedHeight: 400,
      animation:"zoom-in-down",
      embedUrl:
        "https://www.tradingview-widget.com/embed-widget/forex-heat-map/?locale=en#%7B%22width%22%3A970%2C%22height%22%3A320%2C%22currencies%22%3A%5B%22EUR%22%2C%22USD%22%2C%22JPY%22%2C%22GBP%22%2C%22CHF%22%2C%22AUD%22%2C%22CAD%22%2C%22NZD%22%2C%22INR%22%5D%2C%22isTransparent%22%3Afalse%2C%22colorTheme%22%3A%22light%22%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22forex-heat-map%22%2C%22page-uri%22%3A%22forexsalaryconnect.com%2F%3Fpage_id%3D1249%22%7D",
    },
    {
      id: 3,
      title: "Currency Strength Meter",
      animation:"zoom-in-up",
      embedHeight: 630,
      embedUrl:
        "https://widgets.myfxbook.com/widgets/heat-map.html?symbols=8,47,9,10,11,103,12,46,6,13,14,17,7,20,1,107,24,25,4,48,2,26,49,27,28,5,29,3&type=1",
    },
    {
      id: 4,
      title: "Currency Index",
      animation:"zoom-in-down",
      embedHeight: 630,
      embedUrl:
        "https://freeserv.dukascopy.com/2.0/?path=currency_index/index&showHeader=false&bType=0&bDate=1617580800000&bXDays=360&sType=0&sDate=1586736000000&sXDays=360&sbXDays=1&eType=3&eDate=1617840000000&eXDays=1&ebXDays=1&esXDays=360&CHFColor=%23d17918&EURColor=%230000ff&GBPColor=%23117e11&JPYColor=%236700ce&USDColor=%23ff0000&width=100%25&height=500&adv=popup",
    },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div className="py-8">
      <div className="grid gap-4 grid-cols-1">
        {technicalSignals.map((tickerTape) => (
          <div
            key={tickerTape.id}
            className="border p-1 rounded-md bg-transparent shadow-lg"
            data-aos={tickerTape.animation}
          >
            <h3 className="text-xl rounded-md pl-2 py-5 bg-sky-600 text-center text-white  font-semibold mb-2">
              {tickerTape.title}
            </h3>

            <iframe
              title={`tickerTape ${tickerTape.id}`}
              src={tickerTape.embedUrl}
              height={tickerTape.embedHeight}
              className="w-full rounded-md"
              allowtransparency="true"
              lang="en"
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}
