import React from "react";
import PodBanner from "./PodBanner";
import PodShow from "./PodShow";
import PodcastsHelmet from "../Helmets/PodcastsHelmet";
import Partner from "../Homepage/Partner";

export default function Index() {
  return (
    <div className="container">
      <PodcastsHelmet />
      <PodBanner />
      <PodShow />
      {/* <Partner /> */}
    </div>
  );
}
