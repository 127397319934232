import React from "react";
import { Link } from "react-router-dom";

export default function Feedback() {
  return (
    <div>
      <div className="flex max-sm:flex-col items-center justify-center bg-sky-500 text-white px-6 py-3.5 rounded font-[sans-serif]">
        <p className="text-base">
          Your opinion matters to us. We strive to provide the best experience
          possible and your feedback helps us improve.{" "}
        </p>
        <div className="max-sm:mt-4 sm:ml-6 flex gap-4">
          <Link to="/feedback" target="0">
            <button
              type="button"
              className="bg-white text-sky-500 font-semibold py-2 px-4 rounded text-sm hover:bg-slate-100"
            >
              Share Your Experience
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
