import React from "react";
import { Helmet } from "react-helmet";

export default function ForexToolHelmet() {
  return (
    <div>
      <Helmet>
        <title>Forex Tools</title>
        <meta
          name="description"
          content="Unlock the full potential of your trading experience with our suite of cutting-edge Forex tools. Whether you're a seasoned trader or just starting out, our collection of powerful resources is designed to help you make informed decisions and stay ahead of market trends."
        />
      </Helmet>
    </div>
  );
}
