import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import parse from "html-react-parser";
import YoutubeSkelton from "../Alerts/YoutubeSkelton";
import Snackbar from "../Alerts/Snackbar";
import Aos from "aos";
import moment from "moment";

export default function BrokerList() {
  const [brokerDetails, setBrokerDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const res = await axiosClient.get("/api/v1/broker/getbrokerlist");
      const sortedBrokerDetails = res.data.sort(
        (a, b) => moment(b.date) - moment(a.date)
      );

      setBrokerDetails(sortedBrokerDetails);
      // console.log(res.data);
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div>
      {isLoading && <YoutubeSkelton />}
      {!!error ? (
        <Snackbar message={error} type="failed" />
      ) : (
        <div>
          {brokerDetails.map((card, index) => (
            <div
              key={index}
              className="flex w-full m-auto items-center justify-center py-0 sm:py-5"
            >
              <div className="w-full rounded-xl p-2 shadow-2xl hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]  bg-transparent">
                <div className="grid grid-cols-1 gap-0 py-5 mx-auto rounded-lg md:grid-cols-2">
                  <div
                    className="flex flex-col justify-between"
                    data-aos="zoom-in-up"
                  >
                    <div className="group relative flex m-auto overflow-hidden rounded-lg">
                      <div className="grid grid-cols-1 gap-6 lg:grid-cols-12">
                        <div className="grid-cols-1 lg:col-span-3">
                          <div className="mx-auto flex h-[130px] w-[130px] items-center justify-center rounded-full bg-white p-3">
                            <img
                              src={card.url}
                              alt="card.brokerName"
                              className="h-full w-full object-cover hover:scale-[1.02] focus:scale-[1.02] active:scale-100 rounded-lg"
                              data-aos="flip-left"
                              data-aos-offset="300"
                              data-aos-easing="ease-in-sine"
                            />
                          </div>
                        </div>
                        <div className="col-span-1 lg:col-span-9">
                          <div className="text-center lg:text-left">
                            {/* <div className="cursor-pointer bg-white rounded-full shadow p-2 w-fit hover:shadow-md transition duration-300 ease-in-out">
                              <span className="text-blue-500">
                                {card.brokerType}
                              </span>
                            </div> */}
                            <h2 className="text-2xl font-bold text-gray-100 pt-2">
                              {card.brokerName}
                            </h2>

                            <div className="mt-4 text-gray-100">
                              {parse(card.shortDescription)}
                            </div>
                          </div>
                          <p className="text-gray-100 my-4">
                            <span className="font-bold">Broker Type : </span>
                            {card.brokerType}
                          </p>
                          <p className="text-gray-100 my-4">
                            <span className="font-bold">
                              Promotion/Partner Code :{" "}
                            </span>
                            <span className="bg-sky-700 rounded-lg p-2">
                            {card.PromotionCode}</span>
                          </p>
                          <div className="w-auto lg:flex items-center">
                            <a
                              className="block w-fit py-4 px-4 text-center font-heading font-medium text-base text-white border rounded-sm transition duration-200 border-sky-600 hover:border-sky-500 bg-sky-600 hover:bg-sky-500"
                              href={card.MembershipLink}
                              target="0"
                            >
                              {card.buttonType}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <article
                    className="flex flex-col shadow-none rounded-md pt-5 sm:pt-0"
                    data-aos="zoom-in-down"
                  >
                    <iframe
                      src={card.youtubeUrl}
                      frameborder="0"
                      title={card.brokerName}
                      allowfullscreen
                      className="object-cover w-full aspect-video p-1 pt-2 lg:p-4 rounded-3xl"
                    ></iframe>
                  </article>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
