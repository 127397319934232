import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Snackbar from "../Alerts/Snackbar";
import { Card, CardHeader } from "@material-tailwind/react";
import Aos from "aos";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import CardSkelton from "../Alerts/CardSkelton";
import moment from "moment";

export default function Promotion() {
  const [promotionDetails, setPromotionDetails] = useState([]);
  // const [resposeData, setResposeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const res = await axiosClient.get("api/v1/broker/getpromotionbrokerlist");
      const sortedPromotionDetails = res.data.sort(
        (a, b) => moment(b.date) - moment(a.date)
      );
      setPromotionDetails(sortedPromotionDetails);
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);

  return (
    <div>
      {isLoading && <CardSkelton />}
      {!!error ? (
        <Snackbar message={error} type="failed" />
      ) : (
        <div>
          {promotionDetails.map((data, index) => (
            <div
              key={index}
              className="flex w-full items-center justify-center pt-10"
            >
              <div className="w-full rounded-xl shadow-2xl hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]  bg-transparent pt-5">
                <div className="flex flex-col justify-between">
                  <div className="">
                    <div className="grid grid-cols-1 gap-6 lg:grid-cols-12">
                      <div className="grid-cols-1 lg:col-span-3">
                        <div className="flex items-center justify-center  aspect-auto  w-full object-cover  p-5 mx-auto">
                          <img
                            src={data.logoUrl}
                            alt="card.brokerTitle"
                            className="h-full w-full object-cover hover:scale-[1.02] focus:scale-[1.02] bg-white active:scale-100 rounded-lg"
                            data-aos="flip-left"
                          />
                        </div>
                      </div>
                      <div
                        className="col-span-1 lg:col-span-9 w-full"
                        data-aos="zoom-in-up"
                      >
                        <div className="text-center lg:text-left pt-5">
                          <h2 className="text-2xl font-bold text-zinc-700 ">
                            {data.brokerName}
                          </h2>

                          <div className="mt-4">
                            {parse(data.shortDescription)}
                          </div>
                        </div>
                        <p className="text-white my-4">
                          <span className="font-bold">
                            Promotion/Partner Code :
                          </span>
                          <span className="bg-sky-700 rounded-lg p-2">
                            {data.promotionalCode}
                          </span>
                        </p>
                        <div className="w-auto lg:flex items-center mb-5">
                          <a
                            className="block w-fit py-4 px-4 text-center font-heading font-medium text-base text-white border rounded-sm transition duration-200 border-sky-600 hover:border-sky-500 bg-sky-600 hover:bg-sky-500"
                            href={data.urlOpenAccount}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {data.buttonName}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4 mx-auto grid grid-cols-1 gap-4 sm:grid-cols-1 md:mb-8 md:grid-cols-3 md:gap-6 xl:gap-8 justify-center">
                  {(data.promotionalImages || []).map((image, imageIndex) => (
                    <div
                      key={imageIndex}
                      className="group relative flex mx-auto items-end overflow-hidden rounded-lg bg-transparent"
                    >
                      <Card
                        className="w-100 bg-transparent"
                        shadow={false}
                        data-aos="fade-up"
                        data-aos-anchor-placement="center-center"
                      >
                        <CardHeader
                          shadow={false}
                          floated={false}
                          className="p-0 bg-transparent"
                        >
                          <Link
                            to={data.urlOpenAccount}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={image}
                              alt={`promoImage-${imageIndex + 1}`}
                              className="h-full w-full object-cover hover:scale-[1.02] focus:scale-[1.02] active:scale-100 rounded-lg bg-transparent"
                            />
                          </Link>
                        </CardHeader>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
