import Aos from "aos";
import React, { useEffect } from "react";

export default function CourseStructure() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div>
      <div className="mb-12 lg:mb-0">
        <h2 className="mb-6 text-[32px] font-bold text-dark sm:text-[40px] lg:text-[36px] xl:text-[40px]">
          Forex Price Action Course
        </h2>
        <div className="mb-8 flex w-full" data-aos="zoom-out-down">
          <div className="flex rounded-lg w-full bg-transparent  p-8 flex-col">
            <div className="flex items-center mb-3">
              <div className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-sky-100 text-white flex-shrink-0">
                <img
                  src="images/books.svg"
                  className="w-7 h-7"
                  alt="book by forex salary"
                />
              </div>
              <h2 className="text-white text-lg title-font font-medium">
                Course Structure
              </h2>
            </div>
            <div className="flex-grow ">
              <ul className="mt-1 text-left font-medium text-lg leading-none border-sky-200 divide-y divide-sky-200">
                <li>
                  <div className="py-3.5 w-full flex items-center text-sky-500 hover:text-sky-400 hover:bg-forex-5">
                    <span className="ml-5 mr-2.5 w-1 h-7 bg-sky-500 rounded-r-md"></span>
                    <p className="font-bold">
                      Duration:&nbsp;
                      <span className="font-normal text-white text-md">
                        It will be 4 weeks course (1 Month).
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="py-3.5 w-full flex items-center text-sky-500 hover:text-sky-400 hover:bg-forex-5">
                    <span className="ml-5 mr-2.5 w-1 h-7 bg-sky-500 rounded-r-md"></span>
                    <p className="font-bold">
                      Week 1:&nbsp;
                      <span className="font-normal text-white text-md">
                        Basic revisions & understanding market logic.
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="py-3.5 w-full flex items-center text-sky-500 hover:text-sky-400 hover:bg-forex-5">
                    <span className="ml-5 mr-2.5 w-1 h-7 bg-sky-500 rounded-r-md"></span>
                    <p className="font-bold">
                      Week 2:&nbsp;
                      <span className="font-normal text-white text-md">
                        How to enter into a trade.
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="py-3.5 w-full flex items-center text-sky-500 hover:text-sky-400 hover:bg-forex-5">
                    <span className="ml-5 mr-2.5 w-1 h-7 bg-sky-500 rounded-r-md"></span>
                    <p className="font-bold">
                      Week 3:&nbsp;
                      <span className="font-normal text-white text-md">
                        How to put stop loss and profit.
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="py-3.5 w-full flex items-center text-sky-500 hover:text-sky-400 hover:bg-forex-5">
                    <span className="ml-5 mr-2.5 w-1 h-7 bg-sky-500 rounded-r-md"></span>
                    <p className="font-bold">
                      Week 4:&nbsp;
                      <span className="font-normal text-white text-md">
                        Traders psychology
                      </span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-8 flex w-full" data-aos="zoom-out-up">
          <div className="flex rounded-lg w-full  bg-transparent  p-8 flex-col">
            <div className="flex items-center mb-3">
              <div className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-sky-100 text-white flex-shrink-0">
                <img
                  src="images/clock.svg"
                  className="w-7 h-7"
                  alt="clock by forex salary"
                />
              </div>
              <h2 className="text-white text-lg title-font font-medium">
                Timings
              </h2>
            </div>
            <div className="flex-grow">
              <ul className="mt-1 text-left font-medium text-lg leading-none border-sky-200 divide-y divide-sky-200">
                <li>
                  <div className="py-3.5 w-full flex items-center text-sky-500 hover:text-sky-400 hover:bg-forex-5">
                    <span className="ml-5 mr-2.5 w-1 h-7 bg-sky-500 rounded-r-md"></span>
                    <p className="font-bold">
                      Plateform :&nbsp;
                      <span className="font-normal text-white text-md">
                        Classes will be conducted via Zoom
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="py-3.5 w-full flex items-center text-sky-500 hover:text-sky-400 hover:bg-forex-5">
                    <span className="ml-5 mr-2.5 w-1 h-7 bg-sky-500 rounded-r-md"></span>
                    <p className="font-bold">
                      Schedule :&nbsp;
                      <span className="font-normal text-white text-md">
                        All classes will be held on Sunday mornings.
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="py-3.5 w-full flex items-center text-sky-500 hover:text-sky-400 hover:bg-forex-5">
                    <span className="ml-5 mr-2.5 w-1 h-7 bg-sky-500 rounded-r-md"></span>
                    <p className="font-bold">
                      Duration :&nbsp;
                      <span className="font-normal text-white text-md">
                        Each class will last approximately 2 hours.
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="py-3.5 w-full flex items-center text-sky-500 hover:text-sky-400 hover:bg-forex-5">
                    <span className="ml-5 mr-2.5 w-1 h-7 bg-sky-500 rounded-r-md"></span>
                    <p className="font-bold">
                      Capacity :&nbsp;
                      <span className="font-normal text-white text-md">
                        Batch sizes will be limited for optimal attention.
                      </span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-8 flex w-full"  data-aos="zoom-out-down">
          <div className="flex rounded-lg h-full bg-transparent  p-8 flex-col">
            <div className="flex items-center mb-3">
              <div className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-sky-100 text-white flex-shrink-0">
                <img
                  src="images/person.svg"
                  className="w-7 h-7"
                  alt="person by forex salary"
                />
              </div>
              <h2 className="text-white text-lg title-font font-bold">
                Who Can Join
              </h2>
            </div>
            <div className="flex-grow">
              <p className="leading-relaxed text-base">
                You should possess 2-3 months of trading experience and basic
                knowledge of Forex, including demo account trading.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
