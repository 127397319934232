import axios from "axios";

const axiosClient = axios.create({
  baseURL:
    process.env.REACT_APP_BASE_URL ||
    process.env.PUBLIC_URL ||
    "http://localhost:4000",
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("ezy_admin_token"),
  },
  withCredentials: true,
});

export default axiosClient;
