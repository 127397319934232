import Aos from "aos";
import React, { useEffect } from "react";

export default function StockNews() {
  const forexNews = [
    {
      id: 1,
      title: "Latest Indices News",
      embedHeight:500,
      animation:"fade-up-right",
      embedUrl: "https://www.tradingview-widget.com/embed-widget/timeline/?locale=en&market=index#%7B%22market%22%3A%22index%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22regular%22%2C%22width%22%3A480%2C%22height%22%3A830%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22timeline%22%7D",
    },
    {
      id: 2,
      title: "Latest Stocks News",
      embedHeight:500,
      animation:"fade-up-left",
      embedUrl: "https://www.tradingview-widget.com/embed-widget/timeline/?locale=en&market=stock#%7B%22market%22%3A%22stock%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22regular%22%2C%22width%22%3A480%2C%22height%22%3A830%2C%22utm_source%22%3A%22forexsalaryconnect.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22timeline%22%7D",
    },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 5000,
    });
  }, []);
  return (
    <div className="py-8">
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        {forexNews.map((newsForex) => (
          <div
            key={newsForex.id}
            className="border p-1 rounded-md bg-transparent shadow-lg"
            data-aos={newsForex.animation}
          >
            <h3 className="text-xl rounded-md pl-2 py-5 bg-sky-600 text-center text-white font-semibold mb-2">{newsForex.title}</h3>
           
            <iframe
              title={`newsForex ${newsForex.id}`}
              src={newsForex.embedUrl}
              height={newsForex.embedHeight}
              className="w-full rounded-md"
              allowtransparency="true"
              lang="en"
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}
