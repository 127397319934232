import React from "react";
import { Helmet } from "react-helmet";

export default function PodcastsHelmet() {
  return (
    <div>
      <Helmet>
        <title>
          Podcast
        </title>
        <meta
          name="description"
          content="Join forex trading coach Kulwant Rana for in-depth discussions, strategies, and analysis on the forex market. Improve your trading skills and gain valuable insights."
        />
      </Helmet>
    </div>
  );
}
