import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import axiosClient from "../../axios-client";
import ContactSvg from "./ContactSvg";
import Snackbar from "../Alerts/Snackbar";

const FreeToContact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    setError("");
    setFormData((state) => ({ ...state, [name]: value }));
  };

  const onSubmintHandler = async (event) => {
    event.preventDefault();
    setError("");
    setSuccessMessage(""); // Clear success message on new submission
    try {
      setIsSubmitting(true);

      // Input validation
      if (
        !formData.name ||
        !formData.email ||
        !formData.phone ||
        !formData.message
      ) {
        throw new Error("Please fill in all fields.");
      }
      const res = await axiosClient.post("/api/v1/mail/contactus", formData);
      console.log(res);
      // Clear the form after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });

      // Set success message
      setSuccessMessage(res.data.message);
    } catch (err) {
      console.log(err);
      setError(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="py-4 mx-auto container" id="contactSection">
      <section className="overflow-hidden relative z-10">
        <div className="">
          <div className="flex flex-wrap -mx-4 lg:justify-around">
            <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
              <div className="mb-12 lg:mb-0" data-aos="fade-up">
                <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none md:mb-6 group">
                  Get In Touch With Us
                </h2>
                <p className="max-w-md leading-relaxed text-white xl:text-lg">
                  We value your feedback and inquiries. Reach out to us using
                  the contact information below:
                </p>
                <div className="mb-8 pt-10 flex w-full">
                  <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary bg-opacity-5 text-primary sm:h-[70px] sm:max-w-[70px]">
                    <img
                      src="/images/Artboard 1gmail.svg"
                      alt="Email Icon"
                      className="fill-current"
                      width={60}
                      height={60}
                    />
                  </div>
                  <Link to="mailto:forexsalary.India@gmail.com">
                    <div className="w-full">
                      <h4 className="pt-2 mb-1 text-xl font-bold text-white">
                        Email
                      </h4>
                      <p className="text-base text-white">
                        forexsalary.India@gmail.com
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="mb-8 pt-0 flex w-full">
                  <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary bg-opacity-5 text-primary sm:h-[70px] sm:max-w-[70px]">
                    <img
                      src="/images/Artboard 1instagram.svg"
                      alt="Instagram Icon"
                      className="fill-current"
                      width={60}
                      height={60}
                    />
                  </div>
                  <Link to="https://www.instagram.com/forex.salary/" target="0">
                    <div className="w-full">
                      <h4 className="pt-2 mb-1 text-xl font-bold text-white">
                        Instagram
                      </h4>
                      <p className="text-base text-white">
                        instagram.com/forex.salary
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="w-full px-4 lg:w-1/2 xl:w-5/12"
              data-aos="fade-down"
              data-aos-delay="600"
            >
              <div className="relative p-8 bg-white rounded-md shadow sm:p-12">
                {!!error && <p className="p-3 text-red-500 my-2">{error}</p>}
                {!!successMessage && (
                  <Snackbar message={successMessage} type="success" />
                )}
                <form onSubmit={onSubmintHandler}>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-md pt-2 font-semibold leading-6 text-gray-900"
                    >
                      Your Name
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="off"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-forex-10 sm:text-sm sm:leading-6"
                        value={formData.name}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="email"
                      className="block text-md pt-2 font-semibold leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-forex-10 sm:text-sm sm:leading-6"
                        value={formData.email}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="phone"
                      className="block text-md pt-2 font-semibold leading-6 text-gray-900"
                    >
                      Phone number
                    </label>
                    <div className="relative mt-2.5">
                      <input
                        type="tel"
                        name="phone"
                        id="phone"
                        autoComplete="off"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-forex-10 sm:text-sm sm:leading-6"
                        value={formData.phone}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="message"
                      className="block text-md pt-2 font-semibold leading-6 text-gray-900"
                    >
                      Message
                    </label>
                    <div className="mt-2.5">
                      <textarea
                        name="message"
                        id="message"
                        rows={4}
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-forex-10 sm:text-sm sm:leading-6"
                        value={formData.message}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="animate-fade-right animate-once animate-duration-1000 animate-delay-1000 animate-ease-linear pt-2">
                    <button 
                      type="submit"
                      className="flex items-center bg-sky-500 text-white gap-1 px-4 py-2 cursor-pointer font-semibold tracking-widest rounded-md hover:bg-sky-600 duration-300 hover:gap-2 hover:translate-x-3"
                    >
                      {isSubmitting ? "Sending..." : "Send"}
                      <svg
                        className="w-5 h-5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                          stroke-linejoin="round"
                          stroke-linecap="round"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </form>
                <div>
                  <ContactSvg />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FreeToContact;
